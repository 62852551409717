import { createReducer } from '@reduxjs/toolkit';
import { getBrandConfiguration } from '@/features/configuration/configurationActions';
import type { BackgroundTheme, BrandColors } from '@/types/color';

type ConfigurationState = {
  brandColors?: BrandColors;
  backgroundTheme?: BackgroundTheme;
};

const initialState: ConfigurationState = {};

export const configurationReducer = createReducer(initialState, (builder) => {
  builder.addCase(getBrandConfiguration.fulfilled, (state, action) => {
    Object.assign(state, action.payload);
  });
});
