import { createAsyncThunk } from '@/store/utils';
import getApiInstance from '@/api';
import type {
  AncillaryValues,
  PassengerValues,
  PurchaserValues,
} from '@/utils/zod-schema';
import type { AxiosResponse } from 'openapi-client-axios';
import type { SearchFormValues } from '@turnit-ride-ui/webshop-search-widget/widget';
import { createBooking } from '@/features/booking/bookingService';
import type {
  BookingDTO,
  CreateBookingResponseDTO,
  ErrorDTO,
  RemoveAncillaryParametersDTO,
} from '@/types/dto';
import { createAction } from '@reduxjs/toolkit';

export const getSingleBookingByNumberAndEmail = createAsyncThunk<
  BookingDTO,
  {
    bookingNumber: string;
    email: string;
  }
>(
  'booking/getSingleBookingByNumberAndEmail',
  async ({ bookingNumber, email }) => {
    const api = (await getApiInstance()).agentApi;

    const searchResults = (
      await api.BookingsSearch_SearchBookingsCustom(null, {
        reference: bookingNumber,
        purchaserPassengerEmail: email,
        concessionVoucherSearchType: 'ALL',
      })
    ).data.bookingsSearchResults;

    if (searchResults?.length !== 1) {
      throw new Error(
        `Expected 1 search result, found ${searchResults?.length}.`
      );
    }

    const booking = searchResults[0];

    return (await api.Bookings_GetBooking({ bookingId: booking.id! })).data;
  }
);

export const clearBooking = createAction('booking/clearManageBooking');

export const getBookingById = createAsyncThunk<BookingDTO, string>(
  'booking/getBookingById',
  async (id) => {
    const api = (await getApiInstance()).agentApi;

    return (await api.Bookings_GetBooking(id)).data;
  }
);

export const updatePassengers = createAsyncThunk<
  object,
  Array<PassengerValues>
>('booking/updatePassengers', async (passengers, { getState }) => {
  const api = (await getApiInstance()).agentApi;
  const bookingId = getState().booking.booking!.id!;

  const responses = [];

  for (const { id: passengerId, ...rest } of passengers) {
    const response = await api.Bookings_UpdatePassenger(
      { bookingId, passengerId },
      rest
    );
    responses.push(response);
  }

  return responses;
});

export const updatePurchaser = createAsyncThunk<object, PurchaserValues>(
  'booking/updatePurchaser',
  async (purchaser, { getState }) => {
    const api = (await getApiInstance()).agentApi;
    const bookingId = getState().booking.booking!.id!;

    return await api.Bookings_UpdatePurchaser({ bookingId }, purchaser);
  }
);

export const addAncillary = createAsyncThunk<
  Array<AxiosResponse<ErrorDTO>> | void,
  AncillaryValues
>('booking/addAncillary', async (data, { getState }) => {
  const api = (await getApiInstance()).agentApi;
  const bookingId = getState().booking.booking!.id!;

  // TODO: Refactor to single request once this task is completed: https://youtrack.tsolutions.co/issue/BR-49676
  const failedResults = [];

  for (const {
    id,
    bookedOfferId,
    additionalOfferId: offerId,
  } of data.ancillaryOffers || []) {
    const response = await api.BookedOffers_AddAncillary(
      { bookingId, bookedOfferId },
      {
        ancillaryId: id!,
        offerId,
        passengerRefs: [
          data.passengersExternalReferences![0],
          ...data.passengersExternalReferences!.slice(1),
        ],
      }
    );

    if (response.status !== 204) {
      failedResults.push(response);
    }
  }

  if (failedResults.length) {
    return failedResults;
  }
});

export const removeAncillary = createAsyncThunk<
  object,
  Omit<RemoveAncillaryParametersDTO, 'bookingId'>
>('booking/removeAncillary', async (pathParams, { getState }) => {
  const api = (await getApiInstance()).agentApi;
  const bookingId = getState().booking.booking!.id!;

  return (
    await api.BookedOffers_DeleteAncillary({
      bookingId,
      ...pathParams,
    })
  ).data;
});

export const createTicketBooking = createAsyncThunk<
  CreateBookingResponseDTO,
  SearchFormValues['passengers']
>('booking/createTicketBooking', async (passengers, { getState }) => {
  const { outbound, inbound } = getState().purchase;
  const passengerExternalReferences = passengers.map(
    ({ externalReference }) => externalReference!
  );

  const offers = [
    ...Object.values(outbound.selectedOfferMapByLegId),
    ...Object.values(inbound.selectedOfferMapByLegId),
  ].map((offer) => ({ id: offer.id!, passengerExternalReferences }));

  return createBooking(offers, passengers);
});

export const createNonTripOfferBooking = createAsyncThunk<
  CreateBookingResponseDTO,
  SearchFormValues['passengers']
>('booking/createNonTripOfferBooking', async (passengers, { getState }) => {
  const { nonTripOffers } = getState().purchase;
  const passengerExternalReferences = passengers.map(
    ({ externalReference }) => externalReference!
  );
  const offers = nonTripOffers.selectedNonTripOffer?.id
    ? [
        {
          id: nonTripOffers.selectedNonTripOffer?.id,
          passengerExternalReferences,
        },
      ]
    : [];

  return createBooking(offers, passengers);
});
