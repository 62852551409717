import { createSelector, selector } from '@/store/utils';

const selectActiveStep = selector((state) => state.purchase.activeStep);
export const activeStepSelector = createSelector(selectActiveStep);

const selectSearchFormValues = selector(
  (state) => state.purchase.searchFormValues
);
export const searchFormValuesSelector = createSelector(selectSearchFormValues);

// OUTBOUND

const selectOutboundData = selector((state) => state.purchase.outbound);
export const outboundDataSelector = createSelector(selectOutboundData);

const selectSelectedOutboundOfferMap = selector(
  (state) => state.purchase.outbound.selectedOfferMapByLegId
);
export const selectedOutboundOfferMapSelector = createSelector(
  selectSelectedOutboundOfferMap
);

const selectOutboundAdditionalOfferParts = selector(
  (state) => state.purchase.outbound.additionalOffers
);
export const outboundAdditionalOfferPartsSelector = createSelector(
  selectOutboundAdditionalOfferParts
);

// INBOUND

const selectInboundData = selector((state) => state.purchase.inbound);
export const inboundDataSelector = createSelector(selectInboundData);

const selectSelectedInboundOfferMap = selector(
  (state) => state.purchase.inbound.selectedOfferMapByLegId
);
export const selectedInboundOfferMapSelector = createSelector(
  selectSelectedInboundOfferMap
);

const selectInboundAdditionalOfferParts = selector(
  (state) => state.purchase.inbound.additionalOffers
);
export const inboundAdditionalOfferPartsSelector = createSelector(
  selectInboundAdditionalOfferParts
);

// NON-TRIP

const selectNonTripOffers = selector((state) => state.purchase.nonTripOffers);
export const nonTripOffersSelector = createSelector(selectNonTripOffers);
