import type { ReactNode } from 'react';
import { useCallback, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { breakpoints } from '@/utils/breakpoints';
import { useDispatch, useSelector } from '@/store/utils';
import {
  bookingSelector,
  bookingPassengersSelector,
} from '@/features/booking/bookingSelectors';
import ContentCard from '@/components/ContentCard';
import { Typography } from '@/components/primitives/Typography';
import {
  getAdditionalOffersSearch,
  payWithExternalPayment,
  resetPurchase,
  setActiveStep,
} from '@/features/purchase/purchaseActions';
import Divider from '@/components/primitives/Divider';
import type { TransTextKeys } from '@/i18n/trans/text';
import { TransText } from '@/i18n/trans/text';
import AncillaryList from '@/components/purchase/checkout/ancillaries/AncillaryList';
import PurchaserForm from '@/components/purchase/checkout/purchaser/PurchaserForm';
import { Button } from '@/components/primitives/Button';
import BookingExpirationTimer from '@/components/purchase/checkout/BookingExpirationTimer';
import { Icons } from '@/components/icons';
import PassengerList from '@/components/purchase/checkout/passengers/PassengerList';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import {
  passengerSchema,
  type PassengerValues,
  paymentMethodSchema,
  type PaymentMethodValues,
  purchaserSchema,
  type PurchaserValues,
  termsSchema,
  type TermsValues,
} from '@/utils/zod-schema';
import PurchaseFooter from '@/components/purchase/footer/PurchaseFooter';
import { Loadable } from '@/components/Loadable';
import { checkoutLoadingSelector } from '@/features/loading/loadingSelectors';
import PaymentMethod from '@/components/purchase/checkout/payment-method/PaymentMethod';
import useBookingTotalPrice from '@/hooks/useBookingTotalPrice';
import { Input } from '@/components/primitives/Input';
import getApiInstance from '@/api';
import { STEP } from '@/utils/consts';
import { v4 } from 'uuid';
import { toast } from 'react-toastify';
import { TransAlert } from '@/i18n/trans/alert';
import type { PassengerDTO } from '@/types/dto';
import { Form, FormField, FormMessage } from '@/components/primitives/Form';
import { cn } from '@/utils/cn';
import useBookingSummaryData from '@/hooks/useBookingSummaryData';
import {
  clearBooking,
  updatePassengers,
  updatePurchaser,
} from '@/features/booking/bookingActions';
import { useNavigate } from 'react-router-dom';
import RegularBookingSummary from '@/components/booking-summary/RegularBookingSummary';
import { persistor } from '@/store';

const Checkout = () => {
  const dispatch = useDispatch();
  const loading = useSelector(checkoutLoadingSelector);
  const isSmallerThanLaptop = useMediaQuery({
    maxWidth: `${breakpoints.laptop}px`,
  });
  const currentBooking = useSelector(bookingSelector);
  const passengers = useSelector(bookingPassengersSelector);
  const { amount, currency } = useBookingTotalPrice();
  const navigate = useNavigate();

  useEffect(() => {
    if (currentBooking?.id) {
      dispatch(getAdditionalOffersSearch(currentBooking.id));
    }
  }, [currentBooking?.id, dispatch]);

  const passengerListForm = useForm<{
    passengers: Array<PassengerValues>;
  }>({
    resolver: zodResolver(z.object({ passengers: z.array(passengerSchema) })),
    defaultValues: {
      passengers: passengers?.map(
        ({
          id,
          externalReference,
          firstName,
          lastName,
          contactInformation,
          age,
        }) => ({
          id,
          externalReference,
          firstName: firstName?.value || '',
          lastName: lastName?.value || '',
          email: contactInformation?.emailAddress?.value || '',
          phone: { number: contactInformation?.phoneNumber?.value || '' },
          age: age || null,
        })
      ),
    },
  });
  const formPassengers = passengerListForm.watch('passengers');

  const purchaserForm = useForm<PurchaserValues>({
    resolver: zodResolver(purchaserSchema),
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      phone: { number: '' },
    },
  });

  const paymentMethodForm = useForm<PaymentMethodValues>({
    resolver: zodResolver(paymentMethodSchema),
    defaultValues: {
      id: 'SALES_POINT_PAYMENT_TYPE.EXTERNAL_3RD_PARTY',
    },
  });

  const termsForm = useForm<TermsValues>({
    resolver: zodResolver(termsSchema),
    defaultValues: {
      areAccepted: false,
    },
  });

  const getSections = useCallback((): Array<[TransTextKeys, ReactNode]> => {
    if ((currentBooking?.bookedTrips?.length || 0) > 0) {
      return [
        ['passengers', <PassengerList passengerListForm={passengerListForm} />],
        [
          'ancillaries',
          <AncillaryList passengerListForm={passengerListForm} />,
        ],
        [
          'payer',
          <PurchaserForm
            purchaserForm={purchaserForm}
            passengerListForm={passengerListForm}
          />,
        ],
        [
          'paymentMethod',
          <PaymentMethod paymentMethodForm={paymentMethodForm} />,
        ],
      ];
    }

    return [
      ['passengers', <PassengerList passengerListForm={passengerListForm} />],
      [
        'payer',
        <PurchaserForm
          purchaserForm={purchaserForm}
          passengerListForm={passengerListForm}
        />,
      ],
      [
        'paymentMethod',
        <PaymentMethod paymentMethodForm={paymentMethodForm} />,
      ],
    ];
  }, [
    currentBooking?.bookedTrips?.length,
    passengerListForm,
    paymentMethodForm,
    purchaserForm,
  ]);

  const handlePayment = useCallback(async () => {
    const typeId = paymentMethodForm.getValues()['id'];

    dispatch(
      payWithExternalPayment({
        bookingId: currentBooking?.id,
        paidAmount: {
          amount,
          currency,
        },
        transactionId: v4(),
        typeId,
      })
    );
  }, [paymentMethodForm, currentBooking?.id, dispatch, amount, currency]);

  const handlePayClicked = useCallback(async () => {
    const passengersValid = await passengerListForm.trigger();
    const purchaserValid = await purchaserForm.trigger();
    const termsValid = await termsForm.trigger();

    if (passengersValid && purchaserValid && termsValid) {
      await passengerListForm.handleSubmit((form) =>
        dispatch(updatePassengers(form.passengers)).unwrap()
      )();
      await purchaserForm.handleSubmit((form) =>
        dispatch(updatePurchaser(form)).unwrap()
      )();

      handlePayment()
        .then(() => {
          dispatch(resetPurchase());
          dispatch(clearBooking());
          persistor.purge();
          navigate('/success', { state: { bookingId: currentBooking?.id } });
        })
        .catch(() => toast.error(<TransAlert i18nKey="paymentFailed" />));
    }
  }, [
    currentBooking?.id,
    dispatch,
    handlePayment,
    navigate,
    passengerListForm,
    purchaserForm,
    termsForm,
  ]);

  const handleCancelBooking = useCallback(async () => {
    // TODO: Add confirmation modal
    const api = (await getApiInstance()).agentApi;

    if (!currentBooking?.id) {
      return;
    }

    api
      .Bookings_DeleteBooking({ bookingId: currentBooking.id })
      .then(() => {
        dispatch(resetPurchase());
        dispatch(clearBooking());
        persistor.purge();
        dispatch(setActiveStep(STEP.PreSearchSubmit));
      })
      .catch(() => {
        toast.error(<TransAlert i18nKey="bookingCancellationFailed" />);
      });
  }, [currentBooking?.id, dispatch]);

  const parsePassengerValuesToPassengerDto = useCallback(
    (passengers: Array<PassengerValues>): Array<PassengerDTO> =>
      passengers.map(({ age, firstName, lastName, email, phone, ...rest }) => ({
        ...rest,
        age: age ?? undefined,
        firstName: firstName
          ? { value: firstName, isRequired: false }
          : undefined,
        lastName: lastName ? { value: lastName, isRequired: false } : undefined,
        contactInformation:
          !email && !phone.number
            ? undefined
            : {
                isRequired: false,
                ...(email && {
                  emailAddress: { isRequired: false, value: email },
                }),
                ...(phone.number && {
                  phoneNumber: {
                    isRequired: false,
                    value: phone.number,
                  },
                }),
              },
        isCardsRequired: false,
      })),
    []
  );

  const summaryData = useBookingSummaryData({
    ...currentBooking,
    passengers: parsePassengerValuesToPassengerDto(formPassengers),
  });

  return (
    <Loadable loading={loading} overlay>
      <div className="sticky top-0 z-10 flex justify-between bg-background py-2">
        <Typography variant="heading1" asChild>
          <h1>
            <TransText i18nKey="checkout" />
          </h1>
        </Typography>
        <Button
          data-testid="checkout-button"
          variant="tertiary"
          className="h-8 bg-white text-primary"
        >
          <Icons.commerce className="mr-2" />
          <BookingExpirationTimer />
        </Button>
      </div>
      <div className="flex items-start gap-8">
        <div className="flex w-full flex-col gap-4">
          {getSections().map(([paragraphKey, section], key) => (
            <ContentCard
              header={
                isSmallerThanLaptop || key !== 0 ? null : (
                  <Typography variant="subtitle">
                    <TransText i18nKey="bookingDetails" />
                  </Typography>
                )
              }
              key={key}
            >
              <div className="flex flex-col gap-3 p-3 desktop:px-6">
                <Typography
                  variant="subtitle"
                  className="flex gap-1 text-dark"
                  asChild
                >
                  <h2>
                    <span>{key + 1}.</span>
                    <TransText i18nKey={paragraphKey} />
                  </h2>
                </Typography>
                <Divider className="border-gray-200" />
                {section}
              </div>
            </ContentCard>
          ))}
        </div>
        <div className="hidden tablet:block">
          <RegularBookingSummary {...summaryData} />
        </div>
      </div>
      <PurchaseFooter
        content={
          <div className="flex gap-2 tablet:self-center">
            <Form {...termsForm}>
              <FormField
                control={termsForm.control}
                name="areAccepted"
                render={() => (
                  <>
                    <Input
                      type="checkbox"
                      className={cn('h-6 w-6', 'border-error-text')}
                      checked={termsForm.watch('areAccepted')}
                      onChange={({ target }) =>
                        termsForm.setValue('areAccepted', target.checked)
                      }
                    />
                    <div>
                      <Typography className="text-dark tablet:text-nowrap">
                        <TransText
                          i18nKey="iAgreeWithTermsAndConditions"
                          components={{
                            anchor: (
                              <a
                                href="#"
                                target="_blank"
                                className="text-primary"
                              />
                            ),
                          }}
                        />
                      </Typography>
                      <FormMessage />
                    </div>
                  </>
                )}
              />
            </Form>
          </div>
        }
        actionButtons={
          <>
            <Button
              size="large"
              fullWidth
              data-testid="cancel-booking-button"
              onClick={handleCancelBooking}
              className="rounded-xl tablet:h-11 tablet:w-auto tablet:rounded-lg"
              variant="destructive"
            >
              <Icons.cancel />
              <Typography variant="button">
                <TransText i18nKey="cancelBooking" />
              </Typography>
            </Button>
            <Button
              size="large"
              fullWidth
              data-testid="confirm-purchase-button"
              className="rounded-xl tablet:h-11 tablet:w-auto tablet:rounded-lg"
              onClick={handlePayClicked}
              aria-label="Pay button"
            >
              <Icons.lock />
              <Typography variant="subtitle" className="flex gap-1">
                <TransText i18nKey="pay" />
                <span>({amount}</span>
                <span>{currency})</span>
              </Typography>
            </Button>
          </>
        }
      />
    </Loadable>
  );
};

export default Checkout;
