import { useCallback, useEffect, useRef } from 'react';
import { useSelector } from '@/store/utils';
import { bookingSelector } from '@/features/booking/bookingSelectors';
import { downloadBookingTickets } from '@/features/booking/bookingService';

export const useTicketDownload = (downloadTimeout?: number) => {
  const booking = useSelector(bookingSelector);
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>();

  useEffect(() => {
    if (!booking) return;
    if (downloadTimeout) {
      timeoutRef.current = setTimeout(
        () => downloadBookingTickets(booking.id!),
        downloadTimeout
      );
    }

    return () => clearTimeout(timeoutRef.current);
  }, [booking, downloadTimeout]);

  return useCallback(async () => {
    if (!booking) return;
    clearTimeout(timeoutRef.current);
    await downloadBookingTickets(booking.id!);
  }, [booking]);
};
