import type { TransKeys } from '@/i18n/trans/index';
import { createTrans } from '@/i18n/trans/index';
import { Trans } from 'react-i18next';

export const TransAlert = createTrans({
  bookingCancellationFailed: (
    <Trans
      i18nKey="alert.bookingCancellationFailed"
      defaults="Failed to cancel booking"
    />
  ),
  bookingIdMissing: (
    <Trans
      i18nKey="alert.bookingIdMissing"
      defaults="Received booking without id"
    />
  ),
  bookingsSearchFailed: (
    <Trans
      i18nKey="alert.bookingsSearchFailed"
      defaults="Did not find a booking with this email and number. Please check the inserted data."
    />
  ),
  paymentFailed: (
    <Trans i18nKey="alert.paymentFailed" defaults="Payment failed" />
  ),
});

export type TransAlertKeys = TransKeys<typeof TransAlert>;
