import React, { useMemo } from 'react';
import { Typography } from '@/components/primitives/Typography';
import { TransText } from '@/i18n/trans/text';
import Divider from '@/components/primitives/Divider';
import { Icons } from '@/components/icons';
import { formatDateWithDayOfWeek } from '@/utils/date-time';
import type {
  AdmissionDTO,
  BookedTripDTO,
  PassengerDTO,
  RefundOffersDTO,
} from '@/types/dto';
import { useBookingRefundEligibleTrips } from '@/hooks/useBookingRefundEligibleTrips';
import { useSelector } from '@/store/utils';
import {
  refundSummarySelector,
  selectedAdmissionsSelector,
} from '@/features/refund/refundSelectors';
import { bookingSelector } from '@/features/booking/bookingSelectors';

export const RefundOverview: React.FC = () => {
  const { refundEligibleOutboundTrips, refundEligibleInboundTrips } =
    useBookingRefundEligibleTrips();
  const selectedAdmissions = useSelector(selectedAdmissionsSelector);
  const refundSummary = useSelector(refundSummarySelector);
  const currentBooking = useSelector(bookingSelector);

  const passengerMap = useMemo(
    () =>
      new Map(
        currentBooking?.passengers?.map((passenger) => [
          passenger.id!,
          passenger,
        ]) ?? []
      ),
    [currentBooking]
  );

  if (!refundSummary || !refundSummary.refundOffers) {
    return null;
  }

  return (
    <div>
      <RefundSummary
        refundSummary={refundSummary}
        selectedAdmissions={selectedAdmissions}
      />
      <Typography variant="subtitle" className="my-5">
        <TransText i18nKey="youAreAboutToRefundFollowingItems" />
      </Typography>
      <TripSection
        trips={refundEligibleOutboundTrips}
        title={
          <TransText
            i18nKey="outboundTrips"
            values={{ count: refundEligibleOutboundTrips.length }}
          />
        }
        selectedAdmissions={selectedAdmissions}
        passengerMap={passengerMap}
      />
      {refundEligibleInboundTrips.length > 0 && (
        <>
          <Divider className="my-4 border-neutral-light" />
          <TripSection
            trips={refundEligibleInboundTrips}
            title={
              <TransText
                i18nKey="returnTrips"
                values={{ count: refundEligibleInboundTrips.length }}
              />
            }
            selectedAdmissions={selectedAdmissions}
            passengerMap={passengerMap}
          />
        </>
      )}
    </div>
  );
};

const AdmissionDetails: React.FC<{
  admission: AdmissionDTO;
  passengerMap: Map<string, PassengerDTO>;
}> = ({ admission, passengerMap }) => {
  const firstPassengerId = admission.passengerIds![0];
  const passenger = passengerMap.get(firstPassengerId);
  const passengerName = `${passenger?.firstName?.value} ${passenger?.lastName?.value}`;

  return (
    <div className="mt-3 flex flex-col gap-3">
      <AdmissionItem
        icon={<Icons.ticket height={16} width={16} className="text-primary" />}
        title={admission.productSummary || admission.summary}
        subtitle={passengerName}
        price={`${admission.price?.amount} ${admission.price?.currency}`}
      />
      {admission.ancillaries?.map((ancillary, index) => (
        <AdmissionItem
          key={index}
          icon={
            <Icons.bicycle height={16} width={16} className="text-primary" />
          }
          title={ancillary.productSummary}
          subtitle={passengerName}
          price={`${ancillary.price?.amount} ${ancillary.price?.currency}`}
        />
      ))}
    </div>
  );
};

const AdmissionItem: React.FC<{
  icon: React.ReactNode;
  title: React.ReactNode;
  subtitle: string;
  price: string;
}> = ({ icon, title, subtitle, price }) => (
  <div className="flex flex-row items-stretch justify-between">
    <div className="flex flex-row items-start gap-2">
      <div className="flex h-5 w-5 items-center justify-center">{icon}</div>
      <div>
        <Typography variant="body1-bold">{title}</Typography>
        <Typography variant="body2" className="text-neutral">
          {subtitle}
        </Typography>
      </div>
    </div>
    <Typography variant="body2-bold" className="mt-[2px] text-primary">
      {price}
    </Typography>
  </div>
);

const RefundSummary: React.FC<{
  refundSummary: RefundOffersDTO;
  selectedAdmissions: Record<string, AdmissionDTO[]>;
}> = ({ refundSummary: { refundOffers }, selectedAdmissions }) => {
  const { refundableAmount, refundFee } = refundOffers![0];

  const totalAdmissionAmount = Object.values(selectedAdmissions)
    .flat()
    .reduce((sum, admission) => {
      const admissionPrice = admission.price?.amount || 0;
      const ancillariesPrice =
        admission.ancillaries?.reduce(
          (acc, ancillary) => acc + (ancillary.price?.amount || 0),
          0
        ) || 0;
      return sum + admissionPrice + ancillariesPrice;
    }, 0);

  return (
    <>
      <div className="mb-5">
        <Typography variant="paragraph">
          <TransText i18nKey="refundIssuedText" />
        </Typography>
        <div className="mt-5 flex flex-col gap-2 rounded-md rounded-b-none border border-neutral-light px-4 py-3">
          <SummaryItem
            icon={
              <Icons.ticketReturn
                height={16}
                width={16}
                className="text-primary"
              />
            }
            title={<TransText i18nKey="totalPrice" />}
            amount={`${totalAdmissionAmount} ${refundableAmount?.currency}`}
          />
          <SummaryItem
            icon={
              <Icons.coin height={16} width={16} className="text-primary" />
            }
            title={<TransText i18nKey="refundFee" />}
            amount={`${refundFee?.amount} ${refundFee?.currency}`}
          />
        </div>
        <div className="rounded-md rounded-t-none border border-t-0 border-neutral-light bg-primary-pale p-4">
          <SummaryItem
            icon={
              <Icons.buyReturnTicket
                className="text-primary"
                width={16}
                height={16}
              />
            }
            title={<TransText i18nKey="totalRefund" />}
            amount={`${refundableAmount!.amount!} ${refundableAmount?.currency}`}
          />
        </div>
      </div>
      <Divider className="border-neutral-pale" />
    </>
  );
};

const SummaryItem: React.FC<{
  icon: React.ReactNode;
  title: React.ReactNode;
  amount: string;
}> = ({ icon, title, amount }) => (
  <div className="flex flex-row items-center justify-between">
    <div className="flex flex-row justify-items-center gap-2">
      {icon}
      <Typography variant="body2-bold">{title}</Typography>
    </div>
    <Typography variant="body2-bold" className="text-primary">
      {amount}
    </Typography>
  </div>
);

const TripSection: React.FC<{
  trips: BookedTripDTO[];
  title: React.ReactNode;
  selectedAdmissions: Record<string, AdmissionDTO[]>;
  passengerMap: Map<string, PassengerDTO>;
}> = ({ trips, title, selectedAdmissions, passengerMap }) => (
  <div className="rounded-md border border-neutral-light">
    <div className="flex flex-row items-center justify-between rounded-md rounded-b-none border-b border-neutral-light bg-primary-pale px-4 py-3">
      <div className="flex flex-row items-center gap-2">
        <Icons.transportFrom height={16} width={16} className="text-primary" />
        <Typography variant="body1-bold">{title}</Typography>
      </div>
      <Typography variant="body2" className="text-neutral">
        {formatDateWithDayOfWeek(trips[0]?.legs?.[0]?.departureTime)}
      </Typography>
    </div>
    {trips.map((trip) => (
      <div key={trip.id} className="px-4 py-3">
        {trip.legs?.map((leg, index) => {
          const legAdmissions = selectedAdmissions[leg.id!];
          if (!legAdmissions || legAdmissions.length === 0) return null;
          return (
            <div key={leg.id}>
              {index > 0 && <Divider className="my-3 border-neutral-pale" />}
              <div className="flex flex-row items-center gap-1">
                <Typography variant="body1-bold">
                  {leg.originStop?.name}
                </Typography>
                <Icons.oneWayAlt height={16} width={16} />
                <Typography variant="body1-bold">
                  {leg.destinationStop?.name}
                </Typography>
              </div>
              {legAdmissions.map((admission) => (
                <AdmissionDetails
                  key={admission.id}
                  admission={admission}
                  passengerMap={passengerMap}
                />
              ))}
            </div>
          );
        })}
      </div>
    ))}
  </div>
);

export default RefundOverview;
