import { useMemo } from 'react';
import { useSelector } from '@/store/utils';
import { bookingSelector } from '@/features/booking/bookingSelectors';

const useBookingTotalPrice = () => {
  const booking = useSelector(bookingSelector);

  const bookedTripsAmount = useMemo(
    () =>
      booking?.bookedTrips?.reduce(
        (bookedTripsAcc, { bookedOffers }) =>
          bookedTripsAcc +
          (bookedOffers || []).reduce(
            (bookedOffersAcc, { admissions }) =>
              bookedOffersAcc +
              (admissions || []).reduce(
                (admissionsAcc, { price, reservations, ancillaries }) =>
                  admissionsAcc +
                  (price?.amount || 0) +
                  (reservations || []).reduce(
                    (reservationsAcc, { price }) =>
                      reservationsAcc + (price?.amount || 0),
                    0
                  ) +
                  (ancillaries || []).reduce(
                    (ancillariesAcc, { price, reservations }) =>
                      ancillariesAcc +
                      (price?.amount || 0) +
                      (reservations || []).reduce(
                        (reservationsAcc, { price }) =>
                          reservationsAcc + (price?.amount || 0),
                        0
                      ),
                    0
                  ),
                0
              ),
            0
          ),
        0
      ) || 0,
    [booking?.bookedTrips]
  );

  const nonTripOffersAmount = useMemo(
    () =>
      booking?.nonTripOffers?.reduce(
        (offersAcc, { admissions }) =>
          offersAcc +
          (admissions || []).reduce(
            (admissionsAcc, { price, reservations }) =>
              admissionsAcc +
              (price?.amount || 0) +
              (reservations || []).reduce(
                (reservationsAcc, { price }) =>
                  reservationsAcc + (price?.amount || 0),
                0
              ),
            0
          ),
        0
      ) || 0,
    [booking?.nonTripOffers]
  );

  return {
    amount: bookedTripsAmount + nonTripOffersAmount,
    currency: 'SEK',
  };
};

export default useBookingTotalPrice;
