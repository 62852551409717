import { FindBooking } from '@/components/manage-booking/FindBooking';
import { useSelector } from '@/store/utils';
import { bookingSelector } from '@/features/booking/bookingSelectors';
import BookingDetails from '@/components/manage-booking/BookingDetails';
import { manageBookingLoadingSelector } from '@/features/loading/loadingSelectors';
import { Loadable } from '@/components/Loadable';

export const ManageBooking = () => {
  const booking = useSelector(bookingSelector);
  const loading = useSelector(manageBookingLoadingSelector);

  return (
    <Loadable loading={loading}>
      <div className="mx-auto mb-28 mt-16 w-full p-5 tablet:mb-16 tablet:max-w-mobile laptop:max-w-tablet desktop:max-w-laptop">
        {booking ? <BookingDetails /> : <FindBooking />}
      </div>
    </Loadable>
  );
};
