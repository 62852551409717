import type { ReactNode } from 'react';
import { useCallback, useEffect, useMemo } from 'react';
import { STEP } from '@/utils/consts';
import {
  setActiveStep,
  setSelectedInboundJourney,
  setSelectedInboundOfferMap,
  setSelectedOutboundJourney,
  setSelectedOutboundOfferMapByLegId,
  updateSelectedInboundOfferMapLegId,
  updateSelectedOutboundOfferMapLegId,
} from '@/features/purchase/purchaseActions';
import JourneyList from '@/routes/purchase/JourneyList';
import JourneyDetails from '@/routes/purchase/JourneyDetails';
import {
  activeStepSelector,
  inboundDataSelector,
  nonTripOffersSelector,
  outboundDataSelector,
  searchFormValuesSelector,
  selectedInboundOfferMapSelector,
  selectedOutboundOfferMapSelector,
} from '@/features/purchase/purchaseSelectors';
import { useDispatch, useSelector } from '@/store/utils';
import Checkout from '@/routes/purchase/Checkout';
import {
  inboundJourneysLoadingSelector,
  outboundJourneysLoadingSelector,
} from '@/features/loading/loadingSelectors';
import type { CreateBookingResponseDTO, JourneyDTO } from '@/types/dto';
import { TransText } from '@/i18n/trans/text';
import NonTripOfferList from '@/routes/purchase/NonTripOfferList';
import { toast } from 'react-toastify';
import { TransAlert } from '@/i18n/trans/alert';
import type { ApiErrorResponse } from '@/types/api';
import {
  createNonTripOfferBooking,
  createTicketBooking,
  getBookingById,
} from '@/features/booking/bookingActions';
import type { OfferMapByLegId } from '@/types/offer';

export const usePurchaseSteps = () => {
  const dispatch = useDispatch();
  const activeStep = useSelector(activeStepSelector);
  const currentSearchFormValues = useSelector(searchFormValuesSelector);

  const outboundData = useSelector(outboundDataSelector);
  const areOutboundJourneysLoading = useSelector(
    outboundJourneysLoadingSelector
  );

  const inboundData = useSelector(inboundDataSelector);
  const areInboundJourneysLoading = useSelector(inboundJourneysLoadingSelector);

  const nonTripOffers = useSelector(nonTripOffersSelector);

  const getDefaultOfferMap = useCallback(
    (journey: JourneyDTO) =>
      journey?.trips?.reduce<OfferMapByLegId>(
        (tripsAcc, { legs, offers }) => ({
          ...tripsAcc,
          ...legs?.reduce<OfferMapByLegId>((legsAcc, { id }) => {
            if (!id) {
              return legsAcc;
            }

            const firstMatchingOffer = offers?.find(
              ({ coveredLegIds, availablePlaces }) =>
                Boolean(availablePlaces) && coveredLegIds?.includes(id)
            );

            return firstMatchingOffer
              ? { ...legsAcc, [id]: firstMatchingOffer }
              : legsAcc;
          }, {}),
        }),
        {}
      ) ?? {},
    []
  );

  useEffect(() => {
    if (outboundData.selectedJourney) {
      dispatch(
        setSelectedOutboundOfferMapByLegId(
          getDefaultOfferMap(outboundData.selectedJourney)
        )
      );
    }
  }, [dispatch, getDefaultOfferMap, outboundData.selectedJourney]);

  useEffect(() => {
    if (inboundData.selectedJourney) {
      dispatch(
        setSelectedInboundOfferMap(
          getDefaultOfferMap(inboundData.selectedJourney)
        )
      );
    }
  }, [dispatch, getDefaultOfferMap, inboundData.selectedJourney]);

  const handleBookingCreationError = useCallback(
    ({ response }: ApiErrorResponse) => {
      for (const error of response!.data.errors) {
        toast.error(error.message);
      }
    },
    []
  );

  const fetchBookingById = useCallback(
    (createBookingResponse: CreateBookingResponseDTO) => {
      const id = createBookingResponse.booking?.id;
      id
        ? dispatch(getBookingById(id))
            .unwrap()
            .then(() => dispatch(setActiveStep(STEP.Checkout)))
        : toast.error(<TransAlert i18nKey="bookingIdMissing" />);
    },
    [dispatch]
  );

  const steps: Record<STEP, ReactNode> = useMemo(
    () => ({
      [STEP.PreSearchSubmit]: <div />,
      [STEP.OutboundJourney]: (
        <JourneyList
          {...outboundData}
          title={<TransText i18nKey="selectOutboundTrip" />}
          setSelectedJourney={setSelectedOutboundJourney}
          onContinue={() => dispatch(setActiveStep(STEP.OutboundOffer))}
          loading={areOutboundJourneysLoading}
        />
      ),
      [STEP.OutboundOffer]: (
        <JourneyDetails
          title={<TransText i18nKey="selectOutboundFare" />}
          selectedJourney={outboundData.selectedJourney}
          updateSelectedOfferMap={updateSelectedOutboundOfferMapLegId}
          selectedOfferMapByLegIdSelector={selectedOutboundOfferMapSelector}
          onContinue={() => {
            if (inboundData.journeys) {
              return dispatch(setActiveStep(STEP.InboundJourney));
            }

            dispatch(createTicketBooking(currentSearchFormValues!.passengers))
              .unwrap()
              .then(fetchBookingById)
              .catch(handleBookingCreationError);
          }}
          onGoBack={() => dispatch(setActiveStep(STEP.OutboundJourney))}
        />
      ),
      [STEP.InboundJourney]: (
        <JourneyList
          {...inboundData}
          title={<TransText i18nKey="selectReturnTrip" />}
          setSelectedJourney={setSelectedInboundJourney}
          onContinue={() => dispatch(setActiveStep(STEP.InboundOffer))}
          onGoBack={() => dispatch(setActiveStep(STEP.OutboundOffer))}
          loading={areInboundJourneysLoading}
        />
      ),
      [STEP.InboundOffer]: (
        <JourneyDetails
          title={<TransText i18nKey="selectReturnFare" />}
          selectedJourney={inboundData.selectedJourney}
          updateSelectedOfferMap={updateSelectedInboundOfferMapLegId}
          selectedOfferMapByLegIdSelector={selectedInboundOfferMapSelector}
          onContinue={() => {
            dispatch(createTicketBooking(currentSearchFormValues!.passengers))
              .unwrap()
              .then(fetchBookingById)
              .catch(handleBookingCreationError);
          }}
          onGoBack={() => dispatch(setActiveStep(STEP.InboundJourney))}
        />
      ),
      [STEP.NonTripOffer]: (
        <NonTripOfferList
          {...nonTripOffers}
          onContinue={() => {
            dispatch(
              createNonTripOfferBooking(currentSearchFormValues!.passengers)
            )
              .unwrap()
              .then(fetchBookingById)
              .catch(handleBookingCreationError);
          }}
        />
      ),
      [STEP.Checkout]: <Checkout />,
    }),
    [
      outboundData,
      areOutboundJourneysLoading,
      inboundData,
      areInboundJourneysLoading,
      nonTripOffers,
      dispatch,
      currentSearchFormValues,
      fetchBookingById,
      handleBookingCreationError,
    ]
  );

  return {
    steps,
    activeStep,
  };
};
