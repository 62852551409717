import { Icons } from '@/components/icons';
import { Typography } from '@/components/primitives/Typography';
import { TransText } from '@/i18n/trans/text';
import { breakpoints } from '@/utils/breakpoints';
import { cn } from '@/utils/cn';
import type { FC } from 'react';
import { useMediaQuery } from 'react-responsive';

interface SelectedToggleProps {
  isSelected: boolean;
}

const SelectedToggle: FC<SelectedToggleProps> = ({ isSelected }) => {
  const isSmallerThanLaptop = useMediaQuery({
    maxWidth: `${breakpoints.laptop}px`,
  });

  if (!isSmallerThanLaptop) {
    return;
  }

  return (
    <div
      id="selected-toggle"
      className={cn(
        'flex justify-center border-t border-primary/60 bg-primary/20 py-1 text-primary',
        {
          'border-none bg-primary text-white': isSelected,
        }
      )}
    >
      {isSelected ? (
        <div className="flex flex-row justify-center gap-1">
          <Icons.checkCircle width={20} height={20} className="self-center" />
          <Typography variant="body1-bold">
            <TransText i18nKey="selected" />
          </Typography>
        </div>
      ) : (
        <Typography variant="body1-bold">
          <TransText i18nKey="select" />
        </Typography>
      )}
    </div>
  );
};

export default SelectedToggle;
