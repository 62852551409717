import ContentCard from '@/components/ContentCard';
import { Icons } from '@/components/icons';
import { Loadable } from '@/components/Loadable';
import { Button } from '@/components/primitives/Button';
import { Typography } from '@/components/primitives/Typography';
import PurchaseFooter from '@/components/purchase/footer/PurchaseFooter';
import NonTripOfferCard from '@/components/purchase/NonTripOfferCard';
import {
  bookingLoadingSelector,
  nonTripOffersLoadingSelector,
} from '@/features/loading/loadingSelectors';
import { setActiveStep } from '@/features/purchase/purchaseActions';
import { TransText } from '@/i18n/trans/text';
import { useDispatch, useSelector } from '@/store/utils';
import type { NonTripOfferDTO, SearchNonTripOffersDTO } from '@/types/dto';
import { STEP } from '@/utils/consts';
import { getNonTripOfferWarning, getWarningsByTripId } from '@/utils/warning';
import type { FC } from 'react';

interface NonTripOfferListProps extends SearchNonTripOffersDTO {
  selectedNonTripOffer?: NonTripOfferDTO;
  onContinue: () => void;
}

const NonTripOfferList: FC<NonTripOfferListProps> = ({
  nonTripOffers,
  warnings,
  selectedNonTripOffer,
  onContinue,
}) => {
  const dispatch = useDispatch();
  const loadingNonTripOffers = useSelector(nonTripOffersLoadingSelector);
  const loadingBooking = useSelector(bookingLoadingSelector);
  const warningsByTripId = getWarningsByTripId(warnings?.warnings);

  return (
    <Loadable loading={loadingNonTripOffers || loadingBooking}>
      <Typography variant="heading1" className="mb-2" asChild>
        <h1>{<TransText i18nKey="selectTravelPass" />}</h1>
      </Typography>
      {nonTripOffers?.length ? (
        <ContentCard
          header={
            <Typography variant="subtitle" className="text-white">
              <TransText i18nKey="journeyDetailsAndAvailableFares" />
            </Typography>
          }
        >
          <div className="grid w-full grid-cols-1 gap-2 p-4 laptop:grid-cols-4">
            {nonTripOffers.map((nonTripOffer, index) => (
              <NonTripOfferCard
                key={nonTripOffer.id ?? index}
                nonTripOffer={nonTripOffer}
                isSelected={Boolean(
                  selectedNonTripOffer?.id === nonTripOffer.id
                )}
                warningMessage={getNonTripOfferWarning(
                  nonTripOffer,
                  warningsByTripId
                )}
              />
            ))}
          </div>
        </ContentCard>
      ) : (
        <Typography data-testid="no-trips-found-message">
          <TransText i18nKey="noTravelPassesFound" />.
        </Typography>
      )}
      <PurchaseFooter
        actionButtons={
          <>
            <Button
              fullWidth
              size="large"
              data-testid="footer-back-button"
              className="min-w-[140px] rounded-xl tablet:h-11 tablet:w-auto tablet:rounded-lg"
              variant="tertiary"
              onClick={() => dispatch(setActiveStep(STEP.PreSearchSubmit))}
            >
              <Icons.arrowLeft />
              <Typography variant="subtitle">
                <TransText i18nKey="back" />
              </Typography>
            </Button>
            <Button
              fullWidth
              size="large"
              data-testid="footer-continue-button"
              className="min-w-[140px] rounded-xl tablet:h-11 tablet:w-auto tablet:rounded-lg"
              disabled={!selectedNonTripOffer}
              onClick={onContinue}
            >
              <Typography variant="subtitle" className="self-center">
                <TransText i18nKey="continue" />
              </Typography>
              <Icons.arrowRight />
            </Button>
          </>
        }
      />
    </Loadable>
  );
};

export default NonTripOfferList;
