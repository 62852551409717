import type { FC } from 'react';
import { Typography } from '@/components/primitives/Typography';
import { TransText } from '@/i18n/trans/text';

interface ProductDetailsProps {
  passengerCount: number;
  description: string | undefined;
  admissionsAmount: number;
}

const ProductDetails: FC<ProductDetailsProps> = ({
  passengerCount,
  description,
  admissionsAmount,
}) => {
  return (
    <div className="flex flex-row justify-between px-3 pb-3 tablet:items-end tablet:pb-0">
      <div className="flex items-center gap-2">
        <Typography variant="body1-bold">{description}</Typography>
        <Typography variant="body2">
          <TransText
            i18nKey="passengersCount"
            values={{ count: passengerCount }}
          />
        </Typography>
      </div>
      <Typography variant="body1-bold" className="text-primary">
        {admissionsAmount}
      </Typography>
    </div>
  );
};

export default ProductDetails;
