import type { FC } from 'react';
import MobileNavMenu from '@/components/header/MobileNavMenu';
import RegularNavMenu from '@/components/header/RegularNavMenu';
import { useMediaQuery } from 'react-responsive';
import { breakpoints } from '@/utils/breakpoints';
import { TransText } from '@/i18n/trans/text';
import type { NavigationItem } from '@/types/navigation';

const Header: FC = () => {
  const isSmallerThanTablet = useMediaQuery({
    maxWidth: `${breakpoints.tablet}px`,
  });
  const menuItems: Array<NavigationItem> = [
    { path: '/', name: <TransText i18nKey="purchase" />, testId: 'purchase' },
    {
      path: '/manage-booking',
      name: <TransText i18nKey="manageBooking" />,
      testId: 'manage-booking',
    },
  ];

  const Component = isSmallerThanTablet ? MobileNavMenu : RegularNavMenu;

  return (
    <header className="mx-auto flex h-16 items-center bg-background tablet:max-w-mobile laptop:max-w-tablet desktop:max-w-laptop wide:max-w-wide">
      <Component menuItems={menuItems} />
    </header>
  );
};

export default Header;
