import _partition from 'lodash/partition';
import type {
  BookedTripDTO,
  BookingDTO,
  NonTripOfferFeeDTO,
  PassengerDTO,
} from '@/types/dto';
import _keyBy from 'lodash/keyBy';
import type {
  NonTripOfferFeeBookingItem,
  TripBookingItem,
} from '@/types/booking';
import { type NonTripOfferBookingItem } from '@/types/booking';
import { useBookingTrips } from '@/hooks/useBookingTrips';

export interface BookingSummaryData {
  outboundTrips: Array<BookedTripDTO>;
  inboundTrips: Array<BookedTripDTO>;
  outboundTickets: Array<TripBookingItem>;
  inboundTickets: Array<TripBookingItem>;
  ancillaries: Array<TripBookingItem>;
  nonTripOffers: Array<NonTripOfferBookingItem>;
  nonTripOfferFees: Array<NonTripOfferFeeBookingItem>;
}

const useBookingSummaryData = (booking: BookingDTO): BookingSummaryData => {
  const { nonTripOffers, passengers } = booking;
  const { outboundTrips, inboundTrips } = useBookingTrips();
  const allTrips = [...outboundTrips, ...inboundTrips];
  const activeBookedTrips = allTrips.filter(({ bookedOffers }) =>
    bookedOffers?.some(({ admissions }) =>
      admissions?.some(
        ({ status }) => !['EXCHANGED', 'REFUNDED'].includes(status)
      )
    )
  );
  const [activeOutboundTrips, activeInboundTrips] = _partition(
    activeBookedTrips,
    ({ isOutbound }) => isOutbound
  );

  return {
    outboundTrips,
    inboundTrips,
    outboundTickets: getTripData(passengers!, activeOutboundTrips),
    inboundTickets: getTripData(passengers!, activeInboundTrips),
    ancillaries: getAncillariesData(passengers!, activeBookedTrips),
    nonTripOffers: getNonTripOffersData(passengers!, nonTripOffers || []),
    nonTripOfferFees: getNonTripOfferFeesData(nonTripOffers || []),
  };
};

export default useBookingSummaryData;

const getTripData = (
  passengers: Array<PassengerDTO>,
  trips: Array<BookedTripDTO>
): Array<TripBookingItem> =>
  passengers.flatMap((passenger, key) =>
    trips.flatMap(
      ({ legs, bookedOffers }) =>
        legs?.map((leg) => {
          const admission = bookedOffers
            ?.flatMap(({ admissions }) => admissions || [])
            .find(({ coveredLegIds }) => coveredLegIds?.includes(leg.id!));

          return {
            firstName: passenger.firstName?.value,
            lastName: passenger.lastName?.value,
            destinationStopName: leg.destinationStop?.name,
            originStopName: leg.originStop?.name,
            description: admission?.productDescription,
            amount:
              (admission?.price?.amount || 0) +
              (admission?.reservations || []).reduce(
                (acc, { price }) => acc + (price?.amount || 0),
                0
              ),
            currency: admission?.price?.currency,
            passengerIndex: key,
          };
        }) || []
    )
  );

export const getNonTripOffersData = (
  passengers: Array<PassengerDTO>,
  offers: Array<NonTripOfferFeeDTO>
): Array<NonTripOfferBookingItem> =>
  offers.flatMap(
    ({ admissions }) =>
      admissions?.map((admission, index) => ({
        firstName: passengers[index]?.firstName?.value,
        lastName: passengers[index]?.lastName?.value,
        title: admission.description,
        amount:
          (admission.price?.amount || 0) +
          (admission?.reservations || []).reduce(
            (acc, { price }) => acc + (price?.amount || 0),
            0
          ),
        currency: admission.price?.currency,
        passengerIndex: index,
        validFrom: admission.validFrom,
        description: admission.description,
      })) || []
  );

const getNonTripOfferFeesData = (
  offers: Array<NonTripOfferFeeDTO>
): Array<NonTripOfferFeeBookingItem> =>
  offers.flatMap(
    ({ fees }) =>
      fees?.map((fee) => ({
        description: fee.description,
        amount: fee.price?.amount,
        currency: fee.price?.currency,
      })) || []
  );

const getAncillariesData = (
  passengers: Array<PassengerDTO>,
  bookedTrips: Array<BookedTripDTO>
): Array<TripBookingItem> => {
  const passengerMapById = _keyBy(passengers, 'id');
  const allLegs = bookedTrips.flatMap(({ legs }) => legs || []);
  const legMapById = _keyBy(allLegs, 'id');

  return bookedTrips.flatMap(({ bookedOffers }) =>
    (bookedOffers || []).flatMap(({ admissions }) =>
      (admissions || []).flatMap(
        ({ ancillaries, passengerIds, coveredLegIds }) =>
          (ancillaries || []).flatMap((ancillary) =>
            (passengerIds || [])
              .map((passengerId) => {
                const passenger = passengerMapById[passengerId];
                const originLegId = coveredLegIds?.[0];
                const destinationLegId =
                  coveredLegIds?.[coveredLegIds?.length - 1];

                if (!passenger || !originLegId || !destinationLegId) {
                  return null;
                }

                return {
                  originStopName: legMapById[originLegId]?.originStop?.name,
                  destinationStopName:
                    legMapById[destinationLegId]?.destinationStop?.name,
                  amount: ancillary.price?.amount,
                  currency: ancillary.price?.currency,
                  firstName: passenger.firstName?.value,
                  lastName: passenger.lastName?.value,
                  description: ancillary.productDescription,
                  passengerIndex: passengers.findIndex(
                    ({ id }) => id === passengerId
                  ),
                };
              })
              .filter((item) => item !== null)
          )
      )
    )
  );
};
