import { type FC, useState, useCallback } from 'react';
import { Icons } from '@/components/icons';
import { TransText } from '@/i18n/trans/text';
import { Typography } from '@/components/primitives/Typography';
import Divider from '@/components/primitives/Divider';
import { clearBooking } from '@/features/booking/bookingActions';
import { useDispatch, useSelector } from '@/store/utils';
import TripsByDirection from '@/components/manage-booking/TripsByDirection';
import { Button } from '@/components/primitives/Button';
import { bookingSelector } from '@/features/booking/bookingSelectors';
import useBookingSummaryData from '@/hooks/useBookingSummaryData';
import RefundModal from '@/components/manage-booking/refund/RefundModal';
import { useMediaQuery } from 'react-responsive';
import { breakpoints } from '@/utils/breakpoints';
import RegularBookingSummary from '@/components/booking-summary/RegularBookingSummary';
import MobileBookingSummary from '@/components/booking-summary/MobileBookingSummary';
import { useBookingTrips } from '@/hooks/useBookingTrips';
import { useBookingRefundEligibleTrips } from '@/hooks/useBookingRefundEligibleTrips';

interface BookingDetailsProps {}

const BookingDetails: FC<BookingDetailsProps> = () => {
  const dispatch = useDispatch();
  const currentBooking = useSelector(bookingSelector);
  const summaryData = useBookingSummaryData(currentBooking!);
  const [isRefundModalOpen, setIsRefundModalOpen] = useState(false);
  const { outboundTrips, inboundTrips } = useBookingTrips();
  const { refundEligibleOutboundTrips, refundEligibleInboundTrips } =
    useBookingRefundEligibleTrips();
  const isSmallerThanLaptop = useMediaQuery({
    maxWidth: `${breakpoints.laptop}px`,
  });

  const handleGoBack = useCallback(() => {
    dispatch(clearBooking());
  }, [dispatch]);

  return (
    <div className="flex flex-col">
      <div id="go-back-section" className="mb-4 flex items-center gap-2">
        <Icons.arrowLeft onClick={handleGoBack} />
        <div className="flex gap-1">
          <Typography variant="subtitle" asChild>
            <h1>
              <TransText i18nKey="booking" />:
            </h1>
          </Typography>
          <Typography variant="body1">{currentBooking?.code}</Typography>
        </div>
      </div>
      <div className="grid grid-cols-1 tablet:grid-cols-[1fr,auto] tablet:gap-10">
        <div className="flex flex-col">
          <TripsByDirection
            trips={outboundTrips}
            header={
              <TransText
                i18nKey="outboundTrips"
                values={{ count: outboundTrips.length }}
              />
            }
          />
          {!!inboundTrips.length && (
            <>
              <Divider className="my-3" />
              <TripsByDirection
                trips={inboundTrips}
                header={
                  <TransText
                    i18nKey="returnTrip"
                    values={{ count: inboundTrips.length }}
                  />
                }
              />
            </>
          )}
        </div>
        <div className="hidden laptop:block">
          <RegularBookingSummary {...summaryData} />
        </div>
      </div>
      <footer className="fixed bottom-0 left-0 right-0 w-full justify-center border-t bg-white text-primary laptop:flex">
        <div className="border-b border-neutral-light laptop:hidden">
          <MobileBookingSummary {...summaryData} />
        </div>
        <div
          id="footer-content"
          className="flex w-full justify-end gap-2 px-5 py-3 laptop:max-w-tablet desktop:max-w-laptop"
        >
          <Button
            fullWidth={isSmallerThanLaptop}
            variant={isSmallerThanLaptop ? 'secondary' : 'primary'}
            size={isSmallerThanLaptop ? 'large' : 'regular'}
            data-testid="refund-button"
            onClick={() => setIsRefundModalOpen(true)}
            disabled={
              !refundEligibleOutboundTrips.length &&
              !refundEligibleInboundTrips.length
            }
          >
            <Icons.ticketReturn />
            <Typography variant="button">
              <TransText i18nKey="refund" />
            </Typography>
          </Button>
        </div>
      </footer>
      <RefundModal
        isOpen={isRefundModalOpen}
        onClose={() => setIsRefundModalOpen(false)}
      />
    </div>
  );
};

export default BookingDetails;
