import { createAsyncThunk } from '@/store/utils';
import getApiInstance from '@/api';
import { createAction } from '@reduxjs/toolkit';
import type { STEP } from '@/utils/consts';
import type {
  AdditionalOfferPartDTO,
  JourneyDTO,
  NonTripOfferDTO,
  NonTripOfferSearchRequestBodyDTO,
  OfferSearchRequestBodyDTO,
  SearchNonTripOffersDTO,
  SearchOffersDTO,
} from '@/types/dto';
import type { SearchFormValues } from '@turnit-ride-ui/webshop-search-widget/widget';
import type {
  AdditionalOfferItem,
  AdditionalOffersCollection,
  OfferMapByLegId,
  OfferMapItem,
} from '@/types/offer';

export const getOutboundJourneys = createAsyncThunk<
  SearchOffersDTO,
  Omit<
    OfferSearchRequestBodyDTO,
    'currency' | 'promotionCodes' | 'corporateCodes'
  >
>(
  'purchase/getOutboundJourneys',
  async (payload, { dispatch }) => await dispatch(getJourneys(payload)).unwrap()
);

export const getInboundJourneys = createAsyncThunk<
  SearchOffersDTO,
  Omit<
    OfferSearchRequestBodyDTO,
    'currency' | 'promotionCodes' | 'corporateCodes'
  >
>(
  'purchase/getInboundJourneys',
  async (payload, { dispatch }) => await dispatch(getJourneys(payload)).unwrap()
);

export const getJourneys = createAsyncThunk<
  SearchOffersDTO,
  Omit<
    OfferSearchRequestBodyDTO,
    'currency' | 'promotionCodes' | 'corporateCodes'
  >
>('purchase/getJourneys', async (payload) => {
  const api = (await getApiInstance()).agentApi;

  return (
    await api.Offers_FindOffers(null, {
      currency: 'SEK',
      promotionCodes: [],
      corporateCodes: [],
      ...payload,
    })
  ).data;
});

export const getNonTripOffers = createAsyncThunk<
  SearchNonTripOffersDTO,
  Omit<
    NonTripOfferSearchRequestBodyDTO,
    'currency' | 'promotionCodes' | 'corporateCodes'
  >
>('purchase/getNonTripOffers', async (payload) => {
  const api = (await getApiInstance()).agentApi;

  return (
    await api.Offers_FindNonTripOffers(null, {
      currency: 'SEK',
      promotionCodes: [],
      corporateCodes: [],
      ...payload,
    })
  ).data;
});

export const updateSelectedNonTripOffer = createAction<NonTripOfferDTO>(
  'purchase/updateSelectedNonTripOffer'
);

export const setActiveStep = createAction<STEP>('purchase/setActiveStep');

export const setSelectedOutboundJourney = createAction<JourneyDTO>(
  'purchase/setSelectedOutboundJourney'
);

export const setSelectedInboundJourney = createAction<JourneyDTO>(
  'purchase/setSelectedInboundJourney'
);

export const setSelectedOutboundOfferMapByLegId = createAction<OfferMapByLegId>(
  'purchase/setSelectedOutboundOfferMapByLegId'
);

export const updateSelectedOutboundOfferMapLegId = createAction<OfferMapItem>(
  'purchase/updateSelectedOutboundOfferMapLegId'
);

export const setSelectedInboundOfferMap = createAction<OfferMapByLegId>(
  'purchase/setSelectedInboundOfferMap'
);

export const updateSelectedInboundOfferMapLegId = createAction<OfferMapItem>(
  'purchase/updateSelectedInboundOfferMapLegId'
);

export const resetPurchase = createAction<{ startStep: STEP } | undefined>(
  'purchase/resetPurchase'
);

export const getAdditionalOffersSearch = createAsyncThunk<
  {
    outboundAdditionalOffers: Array<AdditionalOffersCollection>;
    inboundAdditionalOffers: Array<AdditionalOffersCollection>;
  },
  string
>('purchase/getAdditionalOffersSearch', async (bookingId, { getState }) => {
  const api = (await getApiInstance()).agentApi;

  const outboundOffersMap =
    getState().purchase.outbound.selectedOfferMapByLegId;
  const outboundOfferIds = Object.values(outboundOffersMap).map(
    ({ id }) => id!
  );

  const inboundOffersMap = getState().purchase.inbound.selectedOfferMapByLegId;
  const inboundOfferIds = Object.values(inboundOffersMap).map(({ id }) => id!);

  const responseItems = (
    await api.BookedOffers_GetAdditionalOffersSearch(
      {
        bookingId,
      },
      {
        bookedOfferIds: [...outboundOfferIds, ...inboundOfferIds],
      }
    )
  ).data.items;

  const mergeOfferParts = ({
    existingParts,
    newParts,
    bookedOfferId,
    additionalOfferId,
  }: {
    existingParts?: Array<AdditionalOfferItem>;
    newParts?: Array<AdditionalOfferPartDTO>;
    bookedOfferId: string;
    additionalOfferId?: string;
  }): Array<AdditionalOfferItem> => [
    ...(existingParts || []),
    ...(newParts || []).map((offer) => ({
      ...offer,
      bookedOfferId,
      additionalOfferId: additionalOfferId!,
    })),
  ];

  const additionalOfferCollectionByOfferId =
    responseItems?.reduce<Record<string, AdditionalOffersCollection>>(
      (
        acc,
        {
          bookedOfferId,
          additionalOfferId,
          ancillaryOfferParts,
          reservationOfferParts,
          admissionOfferParts,
        }
      ) => {
        return !bookedOfferId
          ? acc
          : {
              ...acc,
              [bookedOfferId]: {
                ancillaryOfferParts: mergeOfferParts({
                  existingParts: acc[bookedOfferId]?.ancillaryOfferParts,
                  newParts: ancillaryOfferParts,
                  bookedOfferId,
                  additionalOfferId,
                }),
                reservationOfferParts: mergeOfferParts({
                  existingParts: acc[bookedOfferId]?.reservationOfferParts,
                  newParts: reservationOfferParts,
                  bookedOfferId,
                  additionalOfferId,
                }),
                admissionOfferParts: mergeOfferParts({
                  existingParts: acc[bookedOfferId]?.admissionOfferParts,
                  newParts: admissionOfferParts,
                  bookedOfferId,
                  additionalOfferId,
                }),
              },
            };
      },
      {}
    ) || {};

  return {
    outboundAdditionalOffers: outboundOfferIds.map(
      (id) => additionalOfferCollectionByOfferId[id]
    ),
    inboundAdditionalOffers: inboundOfferIds.map(
      (id) => additionalOfferCollectionByOfferId[id]
    ),
  };
});

export const setSearchFormValues = createAction<SearchFormValues>(
  'purchase/setSearchFormValues'
);

export const payWithExternalPayment = createAsyncThunk<
  unknown,
  {
    bookingId: string | undefined;
    paidAmount: { amount: number; currency: string };
    transactionId: string;
    typeId: string;
  }
>('purchase/payWithExternalPayment', async ({ bookingId, ...data }) => {
  const api = (await getApiInstance()).agentApi;

  if (!bookingId) {
    return;
  }

  return await api.Payments_PayWithExternalPayment({ bookingId }, data);
});
