import type { FC } from 'react';
import type { UseFormReturn } from 'react-hook-form';
import { useFieldArray } from 'react-hook-form';
import { Form } from '@/components/primitives/Form';
import PassengerForm from '@/components/purchase/checkout/passengers/PassengerForm';
import type { PassengerValues } from '@/utils/zod-schema';

type PassengerListProps = {
  passengerListForm: UseFormReturn<{
    passengers: Array<PassengerValues>;
  }>;
};

const PassengerList: FC<PassengerListProps> = ({ passengerListForm }) => {
  const { fields } = useFieldArray({
    control: passengerListForm.control,
    name: 'passengers',
  });

  return (
    <Form {...passengerListForm}>
      <form className="flex flex-col gap-2">
        {fields.map(({ id }, index) => (
          <PassengerForm key={id} index={index} />
        ))}
      </form>
    </Form>
  );
};

export default PassengerList;
