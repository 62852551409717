import { createLoadingSelector } from '@/store/utils';
import {
  getInboundJourneys,
  getOutboundJourneys,
  getNonTripOffers,
  payWithExternalPayment,
} from '@/features/purchase/purchaseActions';
import {
  addAncillary,
  createTicketBooking,
  createNonTripOfferBooking,
  getBookingById,
  getSingleBookingByNumberAndEmail,
  removeAncillary,
} from '@/features/booking/bookingActions';
import {
  initiateRefund,
  deleteRefundOffer,
} from '@/features/refund/refundActions';

export const outboundJourneysLoadingSelector =
  createLoadingSelector(getOutboundJourneys);

export const inboundJourneysLoadingSelector =
  createLoadingSelector(getInboundJourneys);

export const nonTripOffersLoadingSelector =
  createLoadingSelector(getNonTripOffers);

export const bookingLoadingSelector = createLoadingSelector(
  createTicketBooking,
  createNonTripOfferBooking,
  getBookingById
);

export const addAncillaryLoadingSelector = createLoadingSelector(addAncillary);

export const checkoutLoadingSelector = createLoadingSelector(
  addAncillary,
  removeAncillary,
  getBookingById,
  payWithExternalPayment
);

export const manageBookingLoadingSelector = createLoadingSelector(
  getSingleBookingByNumberAndEmail
);

export const successLoadingSelector = createLoadingSelector(getBookingById);

export const refundLoadingSelector = createLoadingSelector(
  initiateRefund,
  deleteRefundOffer
);
