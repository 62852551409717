import { combineReducers } from '@reduxjs/toolkit';
import { loadingReducer } from '@/features/loading/loadingReducer';
import { configurationReducer } from '@/features/configuration/configurationReducer';
import { purchaseReducer } from '@/features/purchase/purchaseReducer';
import { bookingReducer } from '@/features/booking/bookingReducer';
import { refundReducer } from '@/features/refund/refundReducer';
import { persistConfig } from '@/store/redux-persist';
import { persistReducer } from 'redux-persist';

export const rootReducer = combineReducers({
  purchase: purchaseReducer,
  loading: loadingReducer,
  configuration: configurationReducer,
  booking: bookingReducer,
  refund: refundReducer,
});

export const persistedReducer = persistReducer(persistConfig, rootReducer);

export type RootState = ReturnType<typeof rootReducer>;
