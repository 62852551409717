import type { Components } from '@/types/swagger-schemas/agent-schema';
import type { FC } from 'react';
import { Typography } from '@/components/primitives/Typography';

interface LegStopProps {
  stop: Components.Schemas.TurnitOsdmTranslatorApiCommonStopModel;
  day?: string | null;
  time?: string | null;
}

const LegStop: FC<LegStopProps> = ({ stop, day, time }) => {
  return (
    <div className="flex items-center justify-between text-dark tablet:items-start laptop:flex-col">
      <Typography variant="body1-bold" className="text-wrap" asChild>
        <span>{stop.name}</span>
      </Typography>
      <div className="flex gap-2">
        {day && (
          <Typography variant="body1" className="text-neutral">
            {day}
          </Typography>
        )}
        {time && <Typography variant="body1">{time}</Typography>}
      </div>
    </div>
  );
};

export default LegStop;
