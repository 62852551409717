import {
  Form,
  FormControl,
  FormField,
  FormItem,
} from '@/components/primitives/Form';
import { Input } from '@/components/primitives/Input';
import type { UseFormReturn } from 'react-hook-form';
import React, { type FC, useEffect, useState } from 'react';
import type { PassengerValues, PurchaserValues } from '@/utils/zod-schema';
import ToggleableRow from '@/components/purchase/checkout/ToggleableRow';
import { Typography } from '@/components/primitives/Typography';
import { getPassengerName, getPassengerOtherDetails } from '@/utils/passenger';
import { TransText } from '@/i18n/trans/text';

type PurchaseFormProps = {
  purchaserForm: UseFormReturn<PurchaserValues>;
  passengerListForm: UseFormReturn<{
    passengers: Array<PassengerValues>;
  }>;
};

const PurchaserForm: FC<PurchaseFormProps> = ({
  purchaserForm,
  passengerListForm,
}) => {
  const [selectedExternalReference, setSelectedExternalReference] = useState<
    'custom' | string
  >();
  const passengers = passengerListForm.watch('passengers');
  const selectedPurchaser = passengers.find(
    (p) => p.externalReference === selectedExternalReference
  );

  useEffect(() => {
    if (selectedExternalReference == 'custom') {
      return;
    }

    if (!selectedExternalReference) {
      purchaserForm.reset(passengers[0]);
      setSelectedExternalReference(passengers[0]?.externalReference);
      return;
    }
  }, [passengers, purchaserForm, selectedExternalReference]);

  useEffect(() => {
    purchaserForm.reset({
      firstName: selectedPurchaser?.firstName,
      lastName: selectedPurchaser?.lastName,
      email: selectedPurchaser?.email,
      phone: { number: selectedPurchaser?.phone.number },
    });
  }, [
    purchaserForm,
    selectedPurchaser?.email,
    selectedPurchaser?.firstName,
    selectedPurchaser?.lastName,
    selectedPurchaser?.phone.number,
  ]);

  return (
    <div className="grid grid-cols-1 gap-2 laptop:grid-cols-2 laptop:gap-4">
      <div className="flex flex-col gap-2">
        {passengers.map((passenger, key) => (
          <ToggleableRow
            key={passenger.id}
            isActive={selectedExternalReference === passenger.externalReference}
            onClick={() => {
              purchaserForm.reset(passenger);
              setSelectedExternalReference(passenger.externalReference);
            }}
          >
            <Input
              type="radio"
              checked={
                selectedExternalReference === passenger.externalReference
              }
              className="h-5 w-5 accent-primary"
              readOnly
              aria-label="Select payer"
            />
            <div>
              <Typography
                variant="body1-bold"
                className="whitespace-normal break-all text-dark"
              >
                {getPassengerName(passenger, key)}
              </Typography>
              <Typography variant="body2" className="text-neutral-light">
                {getPassengerOtherDetails(passenger)}
              </Typography>
            </div>
          </ToggleableRow>
        ))}
      </div>
      <div className="flex flex-col gap-2">
        <ToggleableRow
          isActive={selectedExternalReference === 'custom'}
          onClick={() => {
            setSelectedExternalReference('custom');
            purchaserForm.reset({
              firstName: '',
              lastName: '',
              email: '',
              phone: { number: '' },
            });
          }}
        >
          <Input
            type="radio"
            checked={selectedExternalReference === 'custom'}
            className="h-5 w-5 accent-primary"
            readOnly
            aria-label="Select custom payer"
          />
          <div>
            <Typography variant="body1-bold" className="text-dark">
              <TransText i18nKey="custom" />
            </Typography>
            <Typography variant="body2" className="text-neutral-light">
              <TransText i18nKey="enterNameEmailAndPhoneNumber" />
            </Typography>
          </div>
        </ToggleableRow>
        {selectedExternalReference === 'custom' && (
          <Form {...purchaserForm}>
            <form>
              <div className="grid grid-cols-1 gap-2 desktop:grid-cols-2 desktop:gap-4">
                <FormField
                  control={purchaserForm.control}
                  name="firstName"
                  render={({ field }) => (
                    <FormItem>
                      <FormControl
                        label={<TransText i18nKey="firstName" />}
                        className="mt-1"
                      >
                        <Input className="bg-white text-dark" {...field} />
                      </FormControl>
                    </FormItem>
                  )}
                />
                <FormField
                  control={purchaserForm.control}
                  name="lastName"
                  render={({ field }) => (
                    <FormItem>
                      <FormControl
                        className="mt-1"
                        label={<TransText i18nKey="lastName" />}
                      >
                        <Input className="bg-white text-dark" {...field} />
                      </FormControl>
                    </FormItem>
                  )}
                />
                <FormField
                  control={purchaserForm.control}
                  name="email"
                  render={({ field }) => (
                    <FormItem>
                      <FormControl
                        label={<TransText i18nKey="email" />}
                        className="mt-1"
                      >
                        <Input className="bg-white text-dark" {...field} />
                      </FormControl>
                    </FormItem>
                  )}
                />
                <FormField
                  control={purchaserForm.control}
                  name="phone.number"
                  render={({ field }) => (
                    <FormItem>
                      <FormControl
                        className="mt-1"
                        label={<TransText i18nKey="phoneNumber" />}
                      >
                        <Input className="bg-white text-dark" {...field} />
                      </FormControl>
                    </FormItem>
                  )}
                />
              </div>
            </form>
          </Form>
        )}
      </div>
    </div>
  );
};

export default PurchaserForm;
