import { createReducer } from '@reduxjs/toolkit';
import {
  initiateRefund,
  deleteRefundOffer,
  toggleLeg,
  toggleAdmission,
  resetSelection,
} from '@/features/refund/refundActions';
import type { AdmissionDTO, RefundOffersDTO } from '@/types/dto';

type RefundState = {
  selectedAdmissions: { [legId: string]: AdmissionDTO[] };
  refundDetails: RefundOffersDTO | null;
};

const initialState: RefundState = {
  selectedAdmissions: {},
  refundDetails: null,
};

export const refundReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(toggleLeg, (state, action) => {
      const { legId, admissions } = action.payload;
      if (admissions.length > 0) {
        state.selectedAdmissions[legId] = admissions;
      } else {
        state.selectedAdmissions = Object.fromEntries(
          Object.entries(state.selectedAdmissions).filter(
            ([key]) => key !== legId
          )
        );
      }
    })
    .addCase(toggleAdmission, (state, action) => {
      const { legId, admissions } = action.payload;
      if (admissions.length > 0) {
        state.selectedAdmissions[legId] = admissions;
      } else {
        state.selectedAdmissions = Object.fromEntries(
          Object.entries(state.selectedAdmissions).filter(
            ([key]) => key !== legId
          )
        );
      }
    })
    .addCase(resetSelection, (state) => {
      state.selectedAdmissions = {};
      state.refundDetails = null;
    })
    .addCase(initiateRefund.fulfilled, (state, action) => {
      state.refundDetails = action.payload;
    })
    .addCase(deleteRefundOffer.fulfilled, (state) => {
      state.refundDetails = null;
    });
});
