import { useSelector } from '@/store/utils';
import { bookingSelector } from '@/features/booking/bookingSelectors';
import type { LegDTO } from '@/types/dto';
import { useMemo } from 'react';

export const useBookingTripLegs = (): {
  outboundLegs: Array<LegDTO>;
  inboundLegs: Array<LegDTO>;
} => {
  const currentBooking = useSelector(bookingSelector);

  const outboundLegs = useMemo(
    () =>
      currentBooking?.bookedTrips
        ?.filter(({ isOutbound }) => isOutbound)
        .flatMap(({ legs }) => legs || []) || [],
    [currentBooking?.bookedTrips]
  );
  const inboundLegs = useMemo(
    () =>
      currentBooking?.bookedTrips
        ?.filter(({ isOutbound }) => !isOutbound)
        .flatMap(({ legs }) => legs || []) || [],
    [currentBooking?.bookedTrips]
  );

  return {
    outboundLegs,
    inboundLegs,
  };
};
