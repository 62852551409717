import { useSelector } from '@/store/utils';
import { bookingSelector } from '@/features/booking/bookingSelectors';
import type { BookedAncillaryDTO } from '@/types/dto';

type PassengerIdsByAncillaryId = Record<
  NonNullable<BookedAncillaryDTO['id']>,
  Array<string>
>;

export const useBookingPassengerIdsByAncillaryId = () => {
  const booking = useSelector(bookingSelector);
  const passengerIdsByAncillaryId: PassengerIdsByAncillaryId = {};

  booking?.bookedTrips?.forEach(({ bookedOffers }) =>
    bookedOffers?.forEach(({ admissions }) =>
      admissions?.forEach(({ passengerIds, ancillaries }) =>
        ancillaries?.forEach(({ id }) => {
          passengerIdsByAncillaryId[id!] = [
            ...(passengerIdsByAncillaryId[id!] || []),
            ...(passengerIds || []),
          ];
        })
      )
    )
  );

  return passengerIdsByAncillaryId;
};
