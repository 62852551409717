import { useDispatch, useSelector } from '@/store/utils';
import { useEffect } from 'react';
import {
  getCalculatedStyleProperties,
  getBackgroundColor,
  setGlobalStyleProperties,
} from '@/utils/color';
import { brandConfigurationSelector } from '@/features/configuration/configurationSelector';
import { getBrandConfiguration } from '@/features/configuration/configurationActions';
import { DEFAULT_RADIUS } from '@/utils/consts';

export const useAppColors = () => {
  const dispatch = useDispatch();
  const currentBrandConfiguration = useSelector(brandConfigurationSelector);

  useEffect(() => {
    const setupColors = async () => {
      const {
        brandColors: newBrandColors,
        backgroundTheme: newBackgroundTheme,
      } = await dispatch(getBrandConfiguration()).unwrap();

      const calculatedStyleProperties =
        getCalculatedStyleProperties(newBrandColors);
      const newBackgroundColor = getBackgroundColor(
        newBackgroundTheme,
        calculatedStyleProperties
      );

      setGlobalStyleProperties(
        {
          ...calculatedStyleProperties,
          background: newBackgroundColor,
          radius: DEFAULT_RADIUS,
        },
        newBackgroundTheme
      );
    };

    if (Object.keys(currentBrandConfiguration).length === 0) {
      setupColors();
    }
  }, [currentBrandConfiguration, dispatch]);

  return null;
};
