import { createSelector, selector } from '@/store/utils';

const selectSelectedAdmissions = selector(
  (state) => state.refund.selectedAdmissions
);
export const selectedAdmissionsSelector = createSelector(
  selectSelectedAdmissions
);

const selectRefundDetails = selector((state) => state.refund.refundDetails);
export const refundDetailsSelector = createSelector(selectRefundDetails);

const selectRefundSummary = selector((state) => state.refund.refundDetails);
export const refundSummarySelector = createSelector(selectRefundSummary);
