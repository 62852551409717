import type { FC } from 'react';
import { useCallback } from 'react';
import type { UseFormReturn } from 'react-hook-form';
import { useFormContext } from 'react-hook-form';
import type { CheckedState } from '@radix-ui/react-checkbox';
import { FormControl, FormItem } from '@/components/primitives/Form';
import { Typography } from '@/components/primitives/Typography';
import ToggleableRow from '@/components/purchase/checkout/ToggleableRow';
import { Input } from '@/components/primitives/Input';
import type { AncillaryValues, PassengerValues } from '@/utils/zod-schema';
import { getPassengerName, getPassengerOtherDetails } from '@/utils/passenger';
import { TransText } from '@/i18n/trans/text';

interface PassengerOptionsProps {
  passengerListForm: UseFormReturn<{
    passengers: Array<PassengerValues>;
  }>;
}

const PassengerOptions: FC<PassengerOptionsProps> = ({ passengerListForm }) => {
  const passengers = passengerListForm.watch('passengers');

  const { watch, setValue } = useFormContext<AncillaryValues>();
  const passengersExternalReferences = watch('passengersExternalReferences');

  const handleChange = useCallback(
    (checked: CheckedState, toggledPassengerExternalReference: string) => {
      if (checked) {
        setValue('passengersExternalReferences', [
          ...passengersExternalReferences,
          toggledPassengerExternalReference,
        ]);
      } else {
        setValue(
          'passengersExternalReferences',
          passengersExternalReferences.filter(
            (extRef) => extRef !== toggledPassengerExternalReference
          )
        );
      }
    },
    [passengersExternalReferences, setValue]
  );

  return passengers?.map((passenger, key) => {
    const isActive = passengersExternalReferences.some(
      (extRef) => extRef === passenger.externalReference
    );

    return (
      <FormItem key={passenger.id}>
        <FormControl hideLabel label={<TransText i18nKey="selectPassengers" />}>
          <ToggleableRow
            isActive={isActive}
            onClick={() =>
              handleChange(!isActive, passenger.externalReference!)
            }
          >
            <div className="flex w-auto items-center">
              <Input
                type="checkbox"
                value={passenger.externalReference}
                className="h-5 w-5 rounded-md border-neutral-light"
                checked={isActive}
                readOnly
                aria-label="Select passenger"
              />
            </div>
            <div className="flex w-full flex-col">
              <Typography variant="body1-bold" className="text-primary">
                {getPassengerName(passenger, key)}
              </Typography>
              <Typography variant="body2" className="text-neutral-light">
                {getPassengerOtherDetails(passenger)}
              </Typography>
            </div>
          </ToggleableRow>
        </FormControl>
      </FormItem>
    );
  });
};

export default PassengerOptions;
