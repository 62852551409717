import type { FC } from 'react';
import { useCallback, useEffect, useState } from 'react';
import type { UseFormReturn } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from '@/store/utils';
import { bookingSelector } from '@/features/booking/bookingSelectors';
import { Typography } from '@/components/primitives/Typography';
import { Form } from '@/components/primitives/Form';
import LegOptions from '@/components/purchase/checkout/ancillaries/ancillary-modal/LegOptions';
import AncillaryOptions from '@/components/purchase/checkout/ancillaries/ancillary-modal/AncillaryOptions';
import PassengerOptions from '@/components/purchase/checkout/ancillaries/ancillary-modal/PassengerOptions';
import { MobileAncillaryModal } from '@/components/purchase/checkout/ancillaries/ancillary-modal/MobileAncillaryModal';
import { Button } from '@/components/primitives/Button';
import { Icons } from '@/components/icons';
import { useMediaQuery } from 'react-responsive';
import { breakpoints } from '@/utils/breakpoints';
import RegularAncillaryModal from '@/components/purchase/checkout/ancillaries/ancillary-modal/RegularAncillaryModal';
import {
  addAncillary,
  getBookingById,
} from '@/features/booking/bookingActions';
import {
  ancillarySchema,
  type AncillaryValues,
  type PassengerValues,
} from '@/utils/zod-schema';
import { TransText } from '@/i18n/trans/text';
import { zodResolver } from '@hookform/resolvers/zod';

interface AncillaryModalProps {
  passengerListForm: UseFormReturn<{
    passengers: Array<PassengerValues>;
  }>;
}

const AncillaryModal: FC<AncillaryModalProps> = ({ passengerListForm }) => {
  const dispatch = useDispatch();
  const bookingId = useSelector(bookingSelector)?.id;
  const form = useForm<AncillaryValues>({
    resolver: zodResolver(ancillarySchema),
    defaultValues: {
      legId: null,
      passengersExternalReferences: [],
      ancillaryOffers: [],
    },
  });
  const { reset, watch, handleSubmit } = form;

  const selectedAncillaryOffers = watch('ancillaryOffers');

  const [isOverlayOpened, setIsOverlayOpened] = useState(false);
  const isSmallerThanTablet = useMediaQuery({
    maxWidth: `${breakpoints.tablet}px`,
  });

  useEffect(() => {
    if (!isOverlayOpened) {
      reset({
        legId: null,
        ancillaryOffers: [],
        passengersExternalReferences: [],
      });
    }
  }, [isOverlayOpened, reset]);

  const onSubmit = useCallback(
    async (values: AncillaryValues) => {
      const { legId, ancillaryOffers, passengersExternalReferences } = values;
      if (!(legId && ancillaryOffers.length && passengersExternalReferences)) {
        // TODO: Should implement "required" logic for fields
        return;
      }

      await dispatch(addAncillary(values))
        .unwrap()
        .then((results) => {
          if (results) {
            // TODO: Result headers contain ids of ancillaries that werent added, show error(s)
            console.log({ results });
          }

          dispatch(getBookingById(bookingId!))
            .unwrap()
            .then(() => {
              setIsOverlayOpened(false);
            });
        });
    },
    [bookingId, dispatch]
  );

  const getSelectedAncillariesAmount = () =>
    selectedAncillaryOffers.reduce(
      (sum, ancillary) => sum + ancillary.amount,
      0
    );

  // TODO: Make Modal scrollable, modal unusable if too many ancillaries loaded in
  const Component = isSmallerThanTablet
    ? MobileAncillaryModal
    : RegularAncillaryModal;

  return (
    <Component
      isOverlayOpened={isOverlayOpened}
      setIsOverlayOpened={setIsOverlayOpened}
    >
      <Form {...form}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="grid min-w-[550px] grid-cols-1 gap-4 p-3 pb-6 tablet:flex-row tablet:gap-10 laptop:min-w-[800px] laptop:grid-cols-2">
            <div className="flex flex-col gap-2">
              <div className="flex flex-col gap-2">
                <Typography variant="label">
                  <TransText i18nKey="selectLeg" />
                </Typography>
                <LegOptions />
              </div>
              <div className="flex flex-col gap-2">
                <Typography variant="label">
                  <TransText i18nKey="selectAncillary" />
                </Typography>
                <AncillaryOptions />
              </div>
            </div>
            <div className="flex flex-col gap-6">
              <div className="flex flex-col gap-2">
                <Typography variant="label">
                  <TransText i18nKey="selectPassengers" />
                </Typography>
                <PassengerOptions passengerListForm={passengerListForm} />
              </div>
              <div className="flex flex-col gap-1">
                <Typography variant="label">
                  <TransText i18nKey="ancillaryPricePerPassenger" />
                </Typography>
                <Typography variant="subtitle" className="text-dark">
                  {getSelectedAncillariesAmount()}
                </Typography>
              </div>
            </div>
          </div>
          <div className="w-full px-4 py-3">
            <Button
              data-testid="ancillary-selection-submit-button"
              className="h-auto w-full gap-2 rounded-xl p-4"
              type="submit"
            >
              <Icons.check />
              <Typography variant="button">
                <TransText i18nKey="confirm" />
              </Typography>
            </Button>
          </div>
        </form>
      </Form>
    </Component>
  );
};

export default AncillaryModal;
