import type { FC, ReactNode } from 'react';
import { useCallback, useMemo } from 'react';
import OfferCard from '@/components/purchase/journey/OfferCard';
import ContentCard from '@/components/ContentCard';
import type { JourneyDTO } from '@/types/dto';
import type { ActionCreatorWithPayload, Selector } from '@reduxjs/toolkit';
import { Typography } from '@/components/primitives/Typography';
import StopDetails from '@/components/purchase/journey/StopDetails';
import type { RootState } from '@/store';
import { Loadable } from '@/components/Loadable';
import { useSelector } from '@/store/utils';
import { bookingLoadingSelector } from '@/features/loading/loadingSelectors';
import PurchaseFooter from '@/components/purchase/footer/PurchaseFooter';
import { Button } from '@/components/primitives/Button';
import { Icons } from '@/components/icons';
import { TransText } from '@/i18n/trans/text';
import type { OfferMapByLegId, OfferMapItem } from '@/types/offer';
import { useMediaQuery } from 'react-responsive';
import { breakpoints } from '@/utils/breakpoints';

interface JourneyDetailsProps {
  selectedJourney?: JourneyDTO;
  updateSelectedOfferMap: ActionCreatorWithPayload<OfferMapItem>;
  selectedOfferMapByLegIdSelector: Selector<RootState, OfferMapByLegId>;
  title: ReactNode;
  onContinue: () => void;
  onGoBack: () => void;
}

const JourneyDetails: FC<JourneyDetailsProps> = ({
  selectedJourney,
  updateSelectedOfferMap,
  selectedOfferMapByLegIdSelector,
  title,
  onContinue,
  onGoBack,
}) => {
  const isSmallerThanLaptop = useMediaQuery({
    maxWidth: `${breakpoints.laptop}px`,
  });
  const loading = useSelector(bookingLoadingSelector);
  const legIds = useMemo(
    () =>
      selectedJourney?.trips?.reduce<Array<string>>(
        (acc, { legs }) => [...acc, ...(legs ?? []).map((leg) => leg.id!)],
        []
      ),
    [selectedJourney?.trips]
  );

  const getTripLegPart = useCallback(
    (legId: string) => {
      if (!legIds || !legIds.length) return;

      return `${legIds.indexOf(legId) + 1}/${legIds.length}`;
    },
    [legIds]
  );

  // FIXME: Some cards throw invalid-gender-requirement error - NSW-1019
  return (
    <Loadable loading={loading}>
      <div className="mb-6 mt-1 flex w-fit flex-col items-center gap-1 laptop:flex-row laptop:gap-4">
        <Typography
          asChild
          variant={isSmallerThanLaptop ? 'heading2' : 'heading1'}
          className="ml-0 mr-auto"
        >
          <h1>{title}</h1>
        </Typography>

        <div className="ml-0 mr-auto flex flex-row items-center gap-1.5 text-neutral-light">
          <Typography
            asChild
            variant={isSmallerThanLaptop ? 'paragraph' : 'heading2-light'}
          >
            <h2>{selectedJourney?.originStop?.name}</h2>
          </Typography>
          <Icons.oneWay />
          <Typography
            asChild
            variant={isSmallerThanLaptop ? 'paragraph' : 'heading2-light'}
          >
            <h2>{selectedJourney?.destinationStop?.name}</h2>
          </Typography>
        </div>
      </div>

      <ContentCard
        header={
          <Typography variant="subtitle" className="text-white">
            <TransText i18nKey="journeyDetailsAndAvailableFares" />
          </Typography>
        }
      >
        {selectedJourney?.trips?.map(({ offers, legs }) =>
          legs?.map((leg, key) => (
            <div
              key={key}
              className="flex flex-col gap-6 p-4 laptop:flex-row laptop:items-start"
            >
              <div className="laptop:sticky laptop:top-4 laptop:flex-shrink-0">
                {legIds?.length && legIds.length > 1 && (
                  <Typography
                    variant="body1-bold"
                    className="mb-1 pl-1.5 text-primary"
                    asChild
                  >
                    <h2>
                      <TransText
                        i18nKey="tripLegPartIndex"
                        values={{ tripLegPart: getTripLegPart(leg.id!) }}
                      />
                    </h2>
                  </Typography>
                )}
                <StopDetails leg={leg} />
              </div>
              <div className="grid w-full auto-rows-fr grid-cols-1 gap-2 laptop:flex-grow laptop:grid-cols-2">
                {offers
                  ?.filter(({ coveredLegIds }) =>
                    coveredLegIds?.includes(leg.id!)
                  )
                  .map((offer, i) => (
                    <OfferCard
                      offer={offer}
                      legId={leg.id}
                      journeyDetails={{
                        originStopName: leg.originStop?.name,
                        destinationStopName: leg.destinationStop?.name,
                      }}
                      updateSelectedOfferMap={updateSelectedOfferMap}
                      selectedOfferMapByLegIdSelector={
                        selectedOfferMapByLegIdSelector
                      }
                      key={i}
                    />
                  ))}
              </div>
            </div>
          ))
        )}
      </ContentCard>
      <PurchaseFooter
        actionButtons={
          <>
            <Button
              size="large"
              fullWidth
              data-testid="footer-back-button"
              className="tablet:h-11 tablet:w-auto tablet:rounded-lg"
              variant="tertiary"
              onClick={onGoBack}
            >
              <Icons.arrowLeft height={20} width={20} />
              <TransText i18nKey="back" />
            </Button>
            <Button
              size="large"
              fullWidth
              data-testid="footer-continue-button"
              className="tablet:h-11 tablet:w-auto tablet:rounded-lg"
              onClick={onContinue}
            >
              <TransText i18nKey="continue" />
              <Icons.arrowRight height={20} width={20} />
            </Button>
          </>
        }
      />
    </Loadable>
  );
};

export default JourneyDetails;
