import { useSelector } from '@/store/utils';
import { bookingSelector } from '@/features/booking/bookingSelectors';
import type { BookedAncillaryDTO, LegDTO } from '@/types/dto';

type AncillariesByLegId = Record<
  NonNullable<LegDTO['id']>,
  Array<BookedAncillaryDTO>
>;

export const useBookingAncillariesByLegId = (): AncillariesByLegId => {
  const booking = useSelector(bookingSelector);
  const ancillariesByLegId: AncillariesByLegId = {};

  booking?.bookedTrips?.forEach(({ bookedOffers }) =>
    bookedOffers?.forEach(({ admissions }) =>
      admissions?.forEach(({ coveredLegIds, ancillaries }) =>
        coveredLegIds?.forEach((id) => {
          ancillariesByLegId[id] = [
            ...(ancillariesByLegId[id] || []),
            ...(ancillaries || []),
          ];
        })
      )
    )
  );

  return ancillariesByLegId;
};
