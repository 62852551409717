import { useMemo } from 'react';
import { useSelector } from '@/store/utils';
import { bookingSelector } from '@/features/booking/bookingSelectors';

export const useBookingTrips = () => {
  const currentBooking = useSelector(bookingSelector);

  const { outboundTrips, inboundTrips } = useMemo(() => {
    const allTrips = currentBooking?.bookedTrips || [];
    const outbound = allTrips.filter((trip) => trip.isOutbound);
    const inbound = allTrips.filter((trip) => !trip.isOutbound);
    return {
      outboundTrips: outbound,
      inboundTrips: inbound,
    };
  }, [currentBooking]);

  return {
    outboundTrips,
    inboundTrips,
  };
};
