import { createSelector, selector } from '@/store/utils';

const selectBooking = selector((state) => state.booking.booking);
export const bookingSelector = createSelector(selectBooking);

const selectBookingConfirmableUntil = selector(
  (state) => state.booking.booking?.confirmableUntil
);
export const bookingConfirmableUntilSelector = createSelector(
  selectBookingConfirmableUntil
);

const selectBookingPassengers = selector(
  (state) => state.booking.booking?.passengers
);
export const bookingPassengersSelector = createSelector(
  selectBookingPassengers
);

const selectBookingId = selector((state) => state.booking.booking?.id);
export const bookingIdSelector = createSelector(selectBookingId);
