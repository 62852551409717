import Divider from '@/components/primitives/Divider';
import { MobileSearchWidget } from '@/components/search-widget/MobileSearchWidget';
import { RegularSearchWidget } from '@/components/search-widget/RegularSearchWidget';
import {
  bookingLoadingSelector,
  inboundJourneysLoadingSelector,
  outboundJourneysLoadingSelector,
} from '@/features/loading/loadingSelectors';
import { useSearchWidgetContext } from '@/hooks/useSearchWidgetContext';
import { useSelector } from '@/store/utils';
import { breakpoints } from '@/utils/breakpoints';
import { DEFAULT_BRAND_COLORS } from '@/utils/consts';
import { SearchWidget } from '@turnit-ride-ui/webshop-search-widget';
import { getQueryParams } from '@turnit-ride-ui/webshop-search-widget/widget/utils/query';
import { useEffect, useMemo, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

export const SearchWidgetWrapper = () => {
  const { handleSubmit } = useSearchWidgetContext();
  const outboundJourneysLoading = useSelector(outboundJourneysLoadingSelector);
  const inboundJourneysLoading = useSelector(inboundJourneysLoadingSelector);
  const journeysLoading = useMemo(
    () => outboundJourneysLoading && inboundJourneysLoading,
    [inboundJourneysLoading, outboundJourneysLoading]
  );
  const bookingLoading = useSelector(bookingLoadingSelector);
  const isSmallerThanLaptop = useMediaQuery({
    maxWidth: `${breakpoints.laptop}px`,
  });
  const [isAutoSubmitted, setIsAutoSubmitted] = useState(false);
  const queryParams = useMemo(() => getQueryParams(), []);

  useEffect(() => {
    const {
      origin,
      destination,
      departureDate,
      returnDate,
      passengers,
      productType,
    } = queryParams;

    if (
      origin?.value &&
      destination?.value &&
      departureDate &&
      passengers &&
      productType &&
      !isAutoSubmitted
    ) {
      handleSubmit({
        origin,
        destination,
        departureDate,
        returnDate,
        passengers,
        productType,
      });
    }

    setIsAutoSubmitted(true);
  }, [handleSubmit, isAutoSubmitted, queryParams]);

  const searchWidget = useMemo(
    () => (
      <SearchWidget
        baseUrl={import.meta.env.VITE_APP_PROXY_TARGET}
        onSubmit={handleSubmit}
        size="sm"
        isLoading={journeysLoading || bookingLoading}
        brandColors={DEFAULT_BRAND_COLORS}
      />
    ),
    [bookingLoading, handleSubmit, journeysLoading]
  );

  return isSmallerThanLaptop ? (
    <>
      <MobileSearchWidget>{searchWidget}</MobileSearchWidget>
      <Divider />
    </>
  ) : (
    <RegularSearchWidget>{searchWidget}</RegularSearchWidget>
  );
};
