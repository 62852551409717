import type { FC, ReactNode } from 'react';
import type { BookedTripDTO } from '@/types/dto';
import { Typography } from '@/components/primitives/Typography';
import { Icons } from '@/components/icons';
import LegCard from '@/components/manage-booking/LegCard';
import TransferTime from '@/components/manage-booking/TransferTime';
import { getDurationBetweenTimestamps } from '@/utils/date-time';
import useBookingAdmissionsByLegId from '@/hooks/useBookingAdmissionsByLegId';

interface TripsByDirectionProps {
  trips: Array<BookedTripDTO>;
  header: ReactNode;
}

const TripsByDirection: FC<TripsByDirectionProps> = ({ trips, header }) => {
  const admissionsByLegId = useBookingAdmissionsByLegId();

  return (
    <div className="flex flex-col">
      <div className="mt-2">{header}</div>
      {trips.map((trip, key) => (
        <div key={key}>
          <Typography
            variant="body2"
            className="mb-2 flex flex-row items-center gap-1 text-neutral-light"
          >
            {trip.originStop?.name}
            <Icons.vectorRight className="h-4 w-4" />
            {trip.destinationStop?.name}
          </Typography>
          {trip.legs?.map((leg, index) => {
            const nextLeg = trip.legs?.[index + 1];
            const transferTime = nextLeg
              ? getDurationBetweenTimestamps(
                  leg.arrivalTime,
                  nextLeg.departureTime
                )
              : '-';

            return (
              <div key={index} className="flex flex-col">
                <LegCard leg={leg} admissions={admissionsByLegId[leg.id!]} />
                {Boolean(transferTime) && (
                  <TransferTime
                    formattedTime={transferTime}
                    arrivalStation={leg.destinationStop?.name}
                    departureStation={nextLeg?.originStop?.name}
                  />
                )}
              </div>
            );
          })}
        </div>
      ))}
    </div>
  );
};

export default TripsByDirection;
