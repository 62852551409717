import { createReducer } from '@reduxjs/toolkit';
import {
  clearBooking,
  getBookingById,
  getSingleBookingByNumberAndEmail,
} from '@/features/booking/bookingActions';
import type { BookingDTO } from '@/types/dto';

type BookingState = { booking: BookingDTO | null };

const initialState: BookingState = { booking: null };

export const bookingReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(clearBooking, (state) => {
      state.booking = null;
    })
    .addCase(getSingleBookingByNumberAndEmail.fulfilled, (state, action) => {
      state.booking = action.payload;
    })
    .addCase(getBookingById.fulfilled, (state, action) => {
      state.booking = action.payload;
    });
});
