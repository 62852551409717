import { Typography } from '@/components/primitives/Typography';
import { TransText } from '@/i18n/trans/text';
import { CardContent, CardPrimitiveRoot } from '@/components/primitives/Card';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { useMediaQuery } from 'react-responsive';
import { breakpoints } from '@/utils/breakpoints';
import { useCallback } from 'react';
import { Form, FormField } from '@/components/primitives/Form';
import { Button } from '@/components/primitives/Button';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { InputField } from '@/components/form/InputField';
import { getSingleBookingByNumberAndEmail } from '@/features/booking/bookingActions';
import { useDispatch } from '@/store/utils';
import { toast } from 'react-toastify';
import { TransAlert } from '@/i18n/trans/alert';

const formSchema = z.object({
  email: z.string().email({ message: 'Email is required' }),
  bookingNumber: z.string().min(1, { message: 'Booking Number is required' }),
});

export const FindBooking = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isSmallerThanTablet = useMediaQuery({
    maxWidth: `${breakpoints.tablet}px`,
  });
  const form = useForm<z.infer<typeof formSchema>>({
    reValidateMode: 'onBlur',
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: '',
      bookingNumber: '',
    },
  });
  const { handleSubmit, control } = form;

  const onSubmit = useCallback(
    async (values: z.infer<typeof formSchema>) => {
      await dispatch(getSingleBookingByNumberAndEmail(values))
        .unwrap()
        .catch(() => {
          toast.warning(<TransAlert i18nKey="bookingsSearchFailed" />);
        });
    },
    [dispatch]
  );

  return (
    <div className="mt-[104px]">
      {/*TODO: Update it with h1 tag once the solution is implemented*/}
      <Typography
        variant={isSmallerThanTablet ? 'mobile-title' : 'title'}
        className="mx-auto text-center laptop:max-w-[410px] laptop:text-left"
      >
        <TransText i18nKey="manageBooking" />
      </Typography>

      <CardPrimitiveRoot className="mx-auto mt-8 rounded-xl laptop:max-w-[410px]">
        <CardContent>
          <Form {...form}>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="space-y-4 [&>*:last-child]:!mt-6"
            >
              <FormField
                control={control}
                name="email"
                render={({ field }) => (
                  <InputField
                    {...field}
                    className="h-10"
                    required
                    placeholder={t('text.enterYourEmailAddress')}
                    label={<TransText i18nKey="emailUsedForBooking" />}
                  />
                )}
              />
              <FormField
                control={form.control}
                name="bookingNumber"
                render={({ field }) => (
                  <InputField
                    {...field}
                    className="h-10"
                    required
                    placeholder={t('text.enterYourBookingNumber')}
                    label={<TransText i18nKey="bookingNumber" />}
                  />
                )}
              />
              <Button
                size="medium"
                fullWidth
                className="rounded-lg"
                data-testid="find-booking-btn"
                type="submit"
              >
                <TransText i18nKey="findYourBooking" />
              </Button>
            </form>
          </Form>
        </CardContent>
      </CardPrimitiveRoot>
    </div>
  );
};
