import type { FC } from 'react';
import ContentCard from '@/components/ContentCard';
import { Typography } from '@/components/primitives/Typography';
import Divider from '@/components/primitives/Divider';
import { Icons } from '@/components/icons';
import { TransText } from '@/i18n/trans/text';
import useBookingTotalPrice from '@/hooks/useBookingTotalPrice';
import SummaryContent from '@/components/booking-summary/SummaryContent';
import type { BookingSummaryData } from '@/hooks/useBookingSummaryData';

interface BookingSummaryProps extends BookingSummaryData {}

const RegularBookingSummary: FC<BookingSummaryProps> = (props) => {
  const { amount, currency } = useBookingTotalPrice();

  return (
    <ContentCard
      header={
        <Typography
          variant="subtitle"
          className="flex justify-between text-white"
        >
          <TransText i18nKey="bookingSummary" />
          <span className="flex gap-1">
            <span>{amount}</span>
            <span>{currency}</span>
          </span>
        </Typography>
      }
      className={{ root: 'max-w-[410px] text-dark' }}
    >
      <SummaryContent {...props} />
      <Divider className="border-t-neutral-light" />
      <div className="flex flex-row justify-between p-4">
        <div className="flex gap-2">
          <Icons.shoppingCart className="text-primary" />
          <Typography variant="body1-bold">
            <TransText i18nKey="totalPrice" />
          </Typography>
        </div>
        <Typography variant="subtitle" className="flex gap-1 text-primary">
          <span>{amount}</span>
          <span>{currency}</span>
        </Typography>
      </div>
    </ContentCard>
  );
};

export default RegularBookingSummary;
