import { useBookingTrips } from '@/hooks/useBookingTrips';
import { useCallback, useMemo } from 'react';
import type { BookedTripDTO } from '@/types/dto';
import { isTimestampInPast } from '@/utils/date-time';

export const useBookingRefundEligibleTrips = () => {
  const { outboundTrips: allOutboundTrips, inboundTrips: allInboundTrips } =
    useBookingTrips();

  const filterRefundEligibleTrips = useCallback((trips: BookedTripDTO[]) => {
    return trips.filter(
      (trip) =>
        trip.legs?.every(
          (leg) => !isTimestampInPast(leg.departureTime!, 'Europe/Stockholm') // TODO: Get timezone dynamically
        ) &&
        trip.bookedOffers?.some((offer) =>
          offer.admissions?.some((admission) => admission.status !== 'REFUNDED')
        )
    );
  }, []);

  const refundEligibleOutboundTrips = useMemo(
    () => filterRefundEligibleTrips(allOutboundTrips),
    [filterRefundEligibleTrips, allOutboundTrips]
  );
  const refundEligibleInboundTrips = useMemo(
    () => filterRefundEligibleTrips(allInboundTrips),
    [filterRefundEligibleTrips, allInboundTrips]
  );

  return { refundEligibleInboundTrips, refundEligibleOutboundTrips };
};
