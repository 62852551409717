import { Icons } from '@/components/icons';
import RefundOverview from '@/components/manage-booking/refund/RefundOverview';
import { RefundSelection } from '@/components/manage-booking/refund/RefundSelection';
import { Button } from '@/components/primitives/Button';
import { Checkbox } from '@/components/primitives/Checkbox';
import {
  Dialog,
  DialogBody,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/primitives/Dialog';
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetFooter,
  SheetHeader,
  SheetTitle,
} from '@/components/primitives/Sheet';
import { Typography } from '@/components/primitives/Typography';
import { getBookingById } from '@/features/booking/bookingActions';
import { bookingSelector } from '@/features/booking/bookingSelectors';
import { refundLoadingSelector } from '@/features/loading/loadingSelectors';
import {
  confirmRefundOffer,
  deleteRefundOffer,
  initiateRefund,
  resetSelection,
} from '@/features/refund/refundActions';
import { selectedAdmissionsSelector } from '@/features/refund/refundSelectors';
import { TransText } from '@/i18n/trans/text';
import { useDispatch, useSelector } from '@/store/utils';
import { breakpoints } from '@/utils/breakpoints';
import { cn } from '@/utils/cn';
import { type FC, useCallback, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

interface RefundModalProps {
  isOpen: boolean;
  onClose: () => void;
}

type Step = 'selection' | 'confirmation';

const RefundModal: FC<RefundModalProps> = ({ isOpen, onClose }) => {
  const isSmallerThanLaptop = useMediaQuery({
    maxWidth: `${breakpoints.laptop}px`,
  });
  const selectedAdmissions = useSelector(selectedAdmissionsSelector);
  const isLoading = useSelector(refundLoadingSelector);
  const [currentStep, setCurrentStep] = useState<Step>('selection');
  const currentBooking = useSelector(bookingSelector);
  const dispatch = useDispatch();
  const [isTermsAccepted, setIsTermsAccepted] = useState(false);

  const handleClose = useCallback(() => {
    onClose();
    dispatch(resetSelection());
    setCurrentStep('selection');
  }, [dispatch, onClose]);

  const handleOpenChange = useCallback(
    async (open: boolean) => {
      if (!open && currentStep === 'confirmation') {
        await dispatch(deleteRefundOffer()).unwrap();
      }
      handleClose();
    },
    [currentStep, dispatch, handleClose]
  );

  const handleBackToSelection = useCallback(async () => {
    await dispatch(deleteRefundOffer()).unwrap();
    setCurrentStep('selection');
  }, [dispatch]);

  const handleInitiateRefund = useCallback(async () => {
    await dispatch(initiateRefund()).unwrap();
    setCurrentStep('confirmation');
  }, [dispatch]);

  const handleConfirmRefund = useCallback(async () => {
    await dispatch(confirmRefundOffer()).unwrap();
    await dispatch(getBookingById(currentBooking!.id!));

    handleClose();
  }, [currentBooking, dispatch, handleClose]);

  const renderFooter = (isMobile: boolean) => {
    return currentStep === 'selection' ? (
      <div
        className={cn('ml-auto mr-0 flex gap-2', {
          'ml-auto flex w-full gap-2': isMobile,
        })}
      >
        <Button
          variant="tertiary"
          data-testid="cancel-refund-btn"
          onClick={handleClose}
          className="w-full laptop:w-fit"
        >
          <TransText i18nKey="cancel" />
        </Button>
        <Button
          data-testid="continue-refund-btn"
          onClick={handleInitiateRefund}
          disabled={!Object.keys(selectedAdmissions).length || isLoading}
          className="w-full laptop:w-fit"
        >
          {isLoading ? (
            <Icons.loader className="mr-2 h-4 w-4 animate-spin" />
          ) : (
            <Icons.ticketReturn height={16} width={16} />
          )}
          <TransText i18nKey="continue" />
        </Button>
      </div>
    ) : (
      <div className="flex w-full items-center justify-between gap-2">
        <div className="w-[150px]">
          <Checkbox
            className="h-6 w-6"
            id="terms-checkbox"
            checked={isTermsAccepted}
            onCheckedChange={(checked) => setIsTermsAccepted(checked === true)}
          >
            <Typography variant="body1">
              <TransText
                i18nKey="iAgreeWithTermsAndConditions"
                components={{
                  anchor: (
                    <a href="#" target="_blank" className="text-primary" />
                  ),
                }}
              />
            </Typography>
          </Checkbox>
        </div>
        <div className="flex items-center gap-2">
          <Button
            variant="tertiary"
            data-testid="back-btn"
            onClick={handleBackToSelection}
            className="w-full laptop:w-fit"
          >
            <Icons.arrowLeft height={16} width={16} />
            <TransText i18nKey="back" />
          </Button>
          <Button
            data-testid="confirm-refund-btn"
            onClick={handleConfirmRefund}
            disabled={isLoading || !isTermsAccepted}
            className="w-full laptop:w-fit"
          >
            {isLoading ? (
              <Icons.loader className="mr-2 h-4 w-4 animate-spin" />
            ) : (
              <Icons.ticketReturn height={16} width={16} />
            )}
            <TransText i18nKey="confirm" />
          </Button>
        </div>
      </div>
    );
  };

  const renderModal = () => {
    const title =
      currentStep === 'selection' ? (
        <TransText i18nKey="chooseTripsToRefund" />
      ) : (
        <TransText
          i18nKey="refundTrips"
          values={{ count: Object.keys(selectedAdmissions).length }}
        />
      );

    return isSmallerThanLaptop ? (
      <Sheet open={isOpen} onOpenChange={handleOpenChange}>
        <SheetContent
          side="bottom"
          className="flex h-full flex-col border-none"
        >
          <SheetHeader className="h-14 shadow-md">
            <div className="ml-4 flex h-full items-center gap-1">
              <Button
                data-testid="refund-sheet-back-btn"
                className="px-0 text-dark"
                variant="ghost"
                onClick={handleClose}
                aria-label="Close"
              >
                <Icons.arrowLeft height={24} width={24} />
              </Button>
              <Typography variant="subtitle">{title}</Typography>
            </div>
          </SheetHeader>
          <SheetTitle className="sr-only">{title}</SheetTitle>
          <SheetDescription className="sr-only">
            <TransText i18nKey="refundModalDescription" />
          </SheetDescription>
          <div className="flex-grow overflow-auto px-6">
            {currentStep === 'selection' ? (
              <RefundSelection />
            ) : (
              <RefundOverview />
            )}
          </div>
          <SheetFooter className="border-t border-neutral-light p-4">
            {renderFooter(isSmallerThanLaptop)}
          </SheetFooter>
        </SheetContent>
      </Sheet>
    ) : (
      <Dialog open={isOpen} onOpenChange={handleOpenChange}>
        <DialogContent className="w-[480px]">
          <DialogHeader>
            <DialogTitle>{title}</DialogTitle>
          </DialogHeader>
          <DialogDescription className="sr-only">
            <TransText i18nKey="refundModalDescription" />
          </DialogDescription>
          <DialogBody>
            <div className="flex-grow overflow-auto px-6">
              {currentStep === 'selection' ? (
                <RefundSelection />
              ) : (
                <RefundOverview />
              )}
            </div>
          </DialogBody>
          <DialogFooter>{renderFooter(isSmallerThanLaptop)}</DialogFooter>
        </DialogContent>
      </Dialog>
    );
  };

  return renderModal();
};

export default RefundModal;
