import type { FC, ReactNode } from 'react';

interface PurchaseFooterProps {
  content?: ReactNode;
  actionButtons: ReactNode;
}

const PurchaseFooter: FC<PurchaseFooterProps> = ({
  content,
  actionButtons,
}) => {
  return (
    <footer className="fixed bottom-0 left-0 right-0 flex w-full justify-center border-t bg-white text-primary">
      <div
        id="footer-content"
        className="flex w-full flex-col gap-2 px-5 py-3 tablet:flex-row tablet:justify-center laptop:max-w-tablet desktop:max-w-laptop"
      >
        {content}
        <div
          id="footer-buttons"
          className="flex w-full flex-row gap-2 tablet:justify-end"
        >
          {actionButtons}
        </div>
      </div>
    </footer>
  );
};

export default PurchaseFooter;
