import { bookingConfirmableUntilSelector } from '@/features/booking/bookingSelectors';
import { useSelector } from '@/store/utils';
import { intervalToDuration, isAfter } from 'date-fns';
import type { FC } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';

let expiryInterval: ReturnType<typeof setInterval>;

const BookingExpirationTimer: FC = () => {
  const confirmableUntil = useSelector(bookingConfirmableUntilSelector);
  const parsedConfirmableUntil = useMemo(
    () => (confirmableUntil ? new Date(confirmableUntil) : new Date()),
    [confirmableUntil]
  );

  const [timeTillExpiry, setTimeTillExpiry] = useState<string>(
    getTimeTillExpiry(new Date(), parsedConfirmableUntil).timeTillExpiry
  );

  const updateExpiryCount = useCallback(() => {
    const { timeTillExpiry, isExpired } = getTimeTillExpiry(
      new Date(),
      parsedConfirmableUntil
    );

    setTimeTillExpiry(timeTillExpiry);

    if (isExpired) {
      clearInterval(expiryInterval);
    }
  }, [parsedConfirmableUntil]);

  useEffect(() => {
    expiryInterval = setInterval(updateExpiryCount, 1000);

    return () => clearInterval(expiryInterval);
  }, [updateExpiryCount]);

  return <>{timeTillExpiry}</>;
};

const getTimeTillExpiry = (start: Date, end: Date) => {
  const isExpired = isAfter(start, end);
  const durationTillExpiry = intervalToDuration({
    start,
    end: isExpired ? start : end,
  });

  const timeTillExpiry =
    durationTillExpiry &&
    [durationTillExpiry.minutes ?? 0, durationTillExpiry.seconds ?? 0]
      .map((num) => String(num).padStart(2, '0'))
      .join(':');

  return { timeTillExpiry, isExpired };
};

export default BookingExpirationTimer;
