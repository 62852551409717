import { createFilter } from 'redux-persist-transform-filter';
import storage from 'redux-persist/lib/storage';

const bookingIdFilter = createFilter('booking', ['booking.id']);

export const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['booking'],
  transforms: [bookingIdFilter],
};
