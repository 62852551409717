import { useSelector } from '@/store/utils';
import { bookingSelector } from '@/features/booking/bookingSelectors';
import type { LegDTO, AdmissionDTO } from '@/types/dto';

const useBookingAdmissionsByLegId = (): Record<
  NonNullable<LegDTO['id']>,
  Array<AdmissionDTO>
> => {
  const booking = useSelector(bookingSelector);

  return (
    booking?.bookedTrips?.reduce(
      (acc, { bookedOffers }) => {
        bookedOffers
          ?.flatMap(({ admissions }) => admissions || [])
          .forEach((admission) => {
            admission.coveredLegIds?.forEach(
              (legId) => (acc[legId] = [...(acc[legId] ?? []), admission])
            );
          });

        return acc;
      },
      {} as Record<NonNullable<LegDTO['id']>, Array<AdmissionDTO>>
    ) || {}
  );
};

export default useBookingAdmissionsByLegId;
