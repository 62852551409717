import type { FC } from 'react';
import type { PaymentTypeDTO } from '@/types/dto';
import type { UseFormReturn } from 'react-hook-form';
import type { PaymentMethodValues } from '@/utils/zod-schema';
import { Typography } from '@/components/primitives/Typography';
import Divider from '@/components/primitives/Divider';
import PaymentMethodRow from '@/components/purchase/checkout/payment-method/PaymentMethodRow';
import { TransText } from '@/i18n/trans/text';
import { paymentMethods } from '@/utils/consts';

interface PaymentMethodProps {
  paymentMethodForm: UseFormReturn<PaymentMethodValues>;
}

const PaymentMethod: FC<PaymentMethodProps> = ({ paymentMethodForm }) => {
  const selectedPaymentMethodId = paymentMethodForm.watch('id');

  const renderPaymentMethodRow = (method: PaymentTypeDTO, key: number) => (
    <PaymentMethodRow
      method={method}
      key={key}
      onClick={() => paymentMethodForm.setValue('id', method.id!)}
      isActive={selectedPaymentMethodId === method.id}
    />
  );

  return (
    <div className="grid w-full grid-cols-1 flex-col gap-4 tablet:grid-cols-[1fr,auto,1fr] tablet:gap-0">
      <div id="banks-section" className="flex flex-col items-start gap-2">
        <Typography variant="subtitle" className="text-dark">
          <TransText i18nKey="banks" />
        </Typography>
      </div>
      <Divider className="mx-3 hidden h-auto border-gray-200 tablet:flex tablet:border-l" />
      <div id="other-payment-methods-section" className="flex flex-col gap-2">
        <Typography variant="subtitle" className="text-dark">
          <TransText i18nKey="other" />
        </Typography>
        {paymentMethods.map(renderPaymentMethodRow)}
      </div>
    </div>
  );
};

export default PaymentMethod;
