import { z } from 'zod';
import { paymentMethods } from '@/utils/consts';

export const passengerSchema = z.object({
  id: z.string(),
  externalReference: z.string(),
  firstName: z.string().min(1),
  lastName: z.string().min(1),
  email: z.string().email(),
  phone: z.object({
    number: z.string().min(1),
  }),
  age: z.number().nullable(),
});

export type PassengerValues = z.infer<typeof passengerSchema>;

export const purchaserSchema = z.object({
  externalReference: z.string().optional(),
  firstName: z.string().min(1),
  lastName: z.string().min(1),
  email: z.string().email(),
  phone: z.object({
    number: z.string().min(1),
  }),
});

export type PurchaserValues = z.infer<typeof purchaserSchema>;

export const paymentMethodSchema = z.object({
  id: z.string().refine((val) => paymentMethods.find(({ id }) => val === id), {
    message: 'Invalid payment method',
  }),
});

export type PaymentMethodValues = z.infer<typeof paymentMethodSchema>;

export const termsSchema = z.object({
  areAccepted: z
    .boolean()
    .refine((val) => val, { message: 'You must accept the terms' }),
});

export type TermsValues = z.infer<typeof termsSchema>;

export const ancillarySchema = z.object({
  passengersExternalReferences: z.array(z.string()),
  legId: z.string().nullable(),
  ancillaryOffers: z.array(
    z.object({
      id: z.string(),
      bookedOfferId: z.string(),
      additionalOfferId: z.string(),
      amount: z.number(),
    })
  ),
});

export type AncillaryValues = z.infer<typeof ancillarySchema>;
