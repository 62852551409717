import { createAsyncThunk } from '@/store/utils';
import type { BackgroundTheme, BrandColors } from '@/types/color';
import { DEFAULT_BACKGROUND_THEME, DEFAULT_BRAND_COLORS } from '@/utils/consts';

export const getBrandConfiguration = createAsyncThunk<
  {
    brandColors: BrandColors;
    backgroundTheme: BackgroundTheme;
  },
  void
>('configuration/getBrandConfiguration', async () => {
  //TODO: Implement actual API call once the endpoint exists
  return {
    brandColors: DEFAULT_BRAND_COLORS,
    backgroundTheme: DEFAULT_BACKGROUND_THEME,
  };
});
