import { type FC, useEffect } from 'react';
import { usePurchaseSteps } from '@/hooks/usePurchaseSteps';
import { cn } from '@/utils/cn';
import { STEP } from '@/utils/consts';
import { SearchWidgetWrapper } from '@/components/search-widget';
import { SearchWidgetContextProvider } from '@/context/SearchWidgetContextProvider';
import { useDispatch, useSelector } from '@/store/utils';
import { bookingIdSelector } from '@/features/booking/bookingSelectors';
import { setActiveStep } from '@/features/purchase/purchaseActions';
import { getBookingById } from '@/features/booking/bookingActions';
import { persistor } from '@/store';

interface PurchaseProps {}

const Purchase: FC<PurchaseProps> = () => {
  const dispatch = useDispatch();
  const { steps, activeStep } = usePurchaseSteps();
  const bookingId = useSelector(bookingIdSelector);

  useEffect(() => {
    if (bookingId) {
      dispatch(getBookingById(bookingId))
        .unwrap()
        .then(() => {
          dispatch(setActiveStep(STEP.Checkout));
        })
        .catch(() => {
          persistor.purge();
        });
    }
  }, [dispatch, bookingId]);

  return (
    <SearchWidgetContextProvider>
      <div className="sticky top-0 z-50 bg-background">
        {STEP.Checkout !== activeStep && <SearchWidgetWrapper />}
      </div>
      <div
        className={cn(
          'mx-auto mb-28 w-full p-5 tablet:mb-16 tablet:max-w-mobile laptop:max-w-tablet desktop:max-w-laptop wide:max-w-wide',
          {
            ['pt-0']: activeStep === STEP.Checkout, // search widget is hidden and content can be closer to header
            ['mb-0 tablet:mb-0']: activeStep === STEP.OutboundJourney, // footer is hidden for outbound journey so bottom padding not needed
            ['mb-36 tablet:mb-24']: activeStep === STEP.Checkout, // booking summary on top of footer takes extra space so additional bottom padding is needed
          }
        )}
      >
        {steps[activeStep]}
      </div>
    </SearchWidgetContextProvider>
  );
};

export default Purchase;
